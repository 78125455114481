// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Semver = require("@gladimdim/bs-semver/lib/js/src/semver.bs.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Validator = require("validator");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Href$BsConsole = require("../Href.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Email$BsConsole = require("../Email.js");
var Image$BsConsole = require("../components/Image.js");
var Shell$BsConsole = require("../shell.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var Services$BsConsole = require("../Services.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var Chip = require("@material-ui/core/Chip");
var BtTypography$BsConsole = require("../BtTypography.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var TokenContext$BsConsole = require("../context/TokenContext.js");
var Radio = require("@material-ui/core/Radio");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var BtAutocomplete$BsConsole = require("../BtAutocomplete.js");
var DataSourcesApi$BsConsole = require("./DataSourcesApi.js");
var Divider = require("@material-ui/core/Divider");
var IdentityVariant$BsConsole = require("../IdentityVariant.js");
var Checkbox = require("@material-ui/core/Checkbox");
var Warning = require("@material-ui/icons/Warning");
var TextField = require("@material-ui/core/TextField");
var RadioGroup = require("@material-ui/core/RadioGroup");
var Typography = require("@material-ui/core/Typography");
var ProjectNameContext$BsConsole = require("../context/ProjectNameContext.js");
var CardContent = require("@material-ui/core/CardContent");
var FormControl = require("@material-ui/core/FormControl");
var ArrowForward = require("@material-ui/icons/ArrowForward");
var CircularProgress = require("@material-ui/core/CircularProgress");
var FormControlLabel = require("@material-ui/core/FormControlLabel");

function responseCb(onSuccess, onFailure, resp) {
  if (resp.tag) {
    var error = resp[0];
    Curry._1(onFailure, error[0] === Task2$BsConsole.CoronerError ? error[1].message : (
            error[0] === DataSourcesApi$BsConsole.ConnectorError ? error[1] : "Error: Something went wrong. This error has been reported to Backtrace."
          ));
  } else {
    Curry._1(onSuccess, undefined);
  }
  
}

function trimValueOrNone(v) {
  var v$1 = v.trim();
  if (v$1 === "") {
    return ;
  } else {
    return v$1;
  }
}

function DataSourcesConnectionForm$FormContainer(Props) {
  var title = Props.title;
  var logoSrc = Props.logoSrc;
  var logoAlt = Props.logoAlt;
  var logoClassName = Props.logoClassName;
  var disabled = Props.disabled;
  var loading = Props.loading;
  var onSave = Props.onSave;
  var onClose = Props.onClose;
  var children = Props.children;
  return React.createElement(React.Fragment, undefined, React.createElement(CardContent.default, {
                  children: React.createElement(Row2$BsConsole.make, {
                        alignItems: /* center */98248149,
                        justifyContent: /* spaceBetween */516682146,
                        children: null
                      }, React.createElement(Row2$BsConsole.make, {
                            alignItems: /* center */98248149,
                            children: null
                          }, React.createElement(Image$BsConsole.make, {
                                className: Css.merge(/* :: */[
                                      Css.style(/* :: */[
                                            Css.marginRight(Css.rem(1)),
                                            /* [] */0
                                          ]),
                                      /* :: */[
                                        logoClassName,
                                        /* [] */0
                                      ]
                                    ]),
                                src: logoSrc,
                                alt: logoAlt
                              }), React.createElement(Typography.default, {
                                variant: "subtitle1",
                                classes: {
                                  root: Css.style(/* [] */0)
                                },
                                children: I18N$BsConsole.showSkip(title)
                              })), React.createElement(Row2$BsConsole.make, {
                            alignItems: /* center */98248149,
                            children: null
                          }, React.createElement(Button.default, {
                                variant: "text",
                                color: "primary",
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.marginRight(Css.rem(0.5)),
                                        /* [] */0
                                      ])
                                },
                                onClick: (function (param) {
                                    return Curry._1(onClose, undefined);
                                  }),
                                children: I18N$BsConsole.show(undefined, "Cancel")
                              }), React.createElement(Button.default, {
                                variant: "contained",
                                color: "primary",
                                disabled: disabled || loading,
                                onClick: (function (_e) {
                                    return Curry._1(onSave, undefined);
                                  }),
                                children: loading ? React.createElement(CircularProgress.default, {
                                        size: 24,
                                        color: "inherit"
                                      }) : I18N$BsConsole.show(undefined, "Save")
                              })))
                }), React.createElement(Divider.default, { }), React.createElement(CardContent.default, {
                  classes: {
                    root: Css.style(/* :: */[
                          Css.overflow(/* auto */-1065951377),
                          /* :: */[
                            Css.selector("a:hover, a:visited", /* :: */[
                                  Css.important(Css.color(Css.hex(Colors$BsConsole.accent))),
                                  /* :: */[
                                    Css.important(Css.textDecoration(/* underline */131142924)),
                                    /* [] */0
                                  ]
                                ]),
                            /* :: */[
                              Css.selector("a", /* :: */[
                                    Css.important(Css.color(Css.hex(Colors$BsConsole.accent))),
                                    /* [] */0
                                  ]),
                              /* [] */0
                            ]
                          ]
                        ])
                  },
                  children: children
                }));
}

var FormContainer = {
  make: DataSourcesConnectionForm$FormContainer
};

function DataSourcesConnectionForm$AttributeMappingInput(Props) {
  var onAddMapping = Props.onAddMapping;
  var onRemoveMapping = Props.onRemoveMapping;
  var attributeMapping = Props.attributeMapping;
  var match = React.useState((function () {
          return "";
        }));
  var setFromValue = match[1];
  var from = match[0];
  var match$1 = React.useState((function () {
          return "";
        }));
  var setToValue = match$1[1];
  var to_ = match$1[0];
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.display(/* flex */-1010954439),
                        /* :: */[
                          Css.alignItems(/* center */98248149),
                          /* :: */[
                            Css.flexWrap(/* nowrap */867913355),
                            /* [] */0
                          ]
                        ]
                      ])
                }, React.createElement(TextField.default, {
                      placeholder: I18N$BsConsole.get(undefined, "'from' attribute name"),
                      value: from,
                      color: "primary",
                      variant: "filled",
                      classes: {
                        root: Css.style(/* :: */[
                              Css.width(Css.px(200)),
                              /* :: */[
                                Css.marginBottom(Css.rem(1)),
                                /* :: */[
                                  Css.marginRight(Css.rem(0.5)),
                                  /* :: */[
                                    Css.selector("input", /* :: */[
                                          Css.important(Css.paddingTop(Css.px(10))),
                                          /* [] */0
                                        ]),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ])
                      },
                      onChange: (function (e) {
                          var value = e.target.value;
                          return Curry._1(setFromValue, (function (param) {
                                        return value;
                                      }));
                        })
                    }), React.createElement(TextField.default, {
                      placeholder: I18N$BsConsole.get(undefined, "'to' attribute name"),
                      value: to_,
                      color: "primary",
                      variant: "filled",
                      classes: {
                        root: Css.style(/* :: */[
                              Css.width(Css.px(200)),
                              /* :: */[
                                Css.marginBottom(Css.rem(1)),
                                /* :: */[
                                  Css.marginRight(Css.rem(0.5)),
                                  /* :: */[
                                    Css.selector("input", /* :: */[
                                          Css.important(Css.paddingTop(Css.px(10))),
                                          /* [] */0
                                        ]),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ])
                      },
                      onChange: (function (e) {
                          return Curry._1(setToValue, e.target.value);
                        })
                    }), React.createElement(Button.default, {
                      variant: "text",
                      color: "primary",
                      disabled: from.trim() === "" && to_.trim() === "",
                      classes: {
                        root: Css.style(/* :: */[
                              Css.marginBottom(Css.rem(1)),
                              /* [] */0
                            ])
                      },
                      onClick: (function (param) {
                          Curry._2(onAddMapping, from, to_);
                          Curry._1(setToValue, (function (param) {
                                  return "";
                                }));
                          return Curry._1(setFromValue, (function (param) {
                                        return "";
                                      }));
                        }),
                      children: I18N$BsConsole.show(undefined, "Add attribute mapping")
                    })), React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.display(/* flex */-1010954439),
                        /* :: */[
                          Css.marginBottom(Css.rem(1)),
                          /* :: */[
                            Css.flexWrap(/* wrap */-822134326),
                            /* [] */0
                          ]
                        ]
                      ])
                }, Belt_Array.mapWithIndex(attributeMapping, (function (idx, param) {
                        var toAtt = param[1];
                        var fromAtt = param[0];
                        return React.createElement(Chip.default, {
                                    label: React.createElement("span", {
                                          className: Css.style(/* :: */[
                                                Css.display(/* flex */-1010954439),
                                                /* :: */[
                                                  Css.alignItems(/* center */98248149),
                                                  /* :: */[
                                                    Css.justifyContent(/* spaceBetween */516682146),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]) + " smaller-icon"
                                        }, I18N$BsConsole.showSkip(fromAtt), React.createElement(ArrowForward.default, { }), I18N$BsConsole.showSkip(toAtt)),
                                    onDelete: (function (param) {
                                        return Curry._2(onRemoveMapping, fromAtt, toAtt);
                                      }),
                                    variant: "outlined",
                                    size: "small",
                                    color: "primary",
                                    classes: {
                                      root: Css.style(/* :: */[
                                            Css.marginRight(Css.rem(0.25)),
                                            /* :: */[
                                              Css.marginBottom(Css.rem(0.25)),
                                              /* [] */0
                                            ]
                                          ])
                                    },
                                    key: "AttributeMappingInput" + (fromAtt + (toAtt + String(idx)))
                                  });
                      }))));
}

var AttributeMappingInput = {
  make: DataSourcesConnectionForm$AttributeMappingInput
};

var container = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(/* center */98248149),
        /* :: */[
          Css.flexWrap(/* nowrap */867913355),
          /* [] */0
        ]
      ]
    ]);

var textField = {
  root: Css.style(/* :: */[
        Css.width(Css.px(200)),
        /* :: */[
          Css.marginBottom(Css.rem(1)),
          /* :: */[
            Css.marginRight(Css.rem(0.5)),
            /* :: */[
              Css.selector("input", /* :: */[
                    Css.important(Css.paddingTop(Css.px(10))),
                    /* [] */0
                  ]),
              /* [] */0
            ]
          ]
        ]
      ])
};

var button = {
  root: Css.style(/* :: */[
        Css.marginBottom(Css.rem(1)),
        /* [] */0
      ])
};

var chipContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.marginBottom(Css.rem(1)),
        /* :: */[
          Css.flexWrap(/* wrap */-822134326),
          /* [] */0
        ]
      ]
    ]);

var chipLabel = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(/* center */98248149),
        /* :: */[
          Css.justifyContent(/* spaceBetween */516682146),
          /* [] */0
        ]
      ]
    ]) + " smaller-icon";

var Style = {
  container: container,
  textField: textField,
  button: button,
  chipContainer: chipContainer,
  chipLabel: chipLabel
};

function DataSourcesConnectionForm$NotificationRecipientsInput(Props) {
  var users = Props.users;
  var onAddRecipient = Props.onAddRecipient;
  var onRemoveRecipient = Props.onRemoveRecipient;
  var notificationRecipients = Props.notificationRecipients;
  var match = React.useState((function () {
          return "";
        }));
  var setRecipient = match[1];
  var recipient = match[0];
  return React.createElement(React.Fragment, undefined, React.createElement(BtAutocomplete$BsConsole.make, {
                  toString: (function (user$prime) {
                      return user$prime.email;
                    }),
                  freeSolo: true,
                  considerations: Belt_List.toArray(users),
                  onChange: (function (raw, user$prime) {
                      return Curry._1(setRecipient, (function (param) {
                                    if (user$prime !== undefined) {
                                      return user$prime.email;
                                    } else {
                                      return raw;
                                    }
                                  }));
                    }),
                  placeholder: I18N$BsConsole.get(undefined, "recipient email"),
                  autoFocus: false,
                  inputAriaLabel: I18N$BsConsole.get(undefined, "Attribute"),
                  renderListOption: (function (user, idx) {
                      return React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Body1 */8,
                                  children: I18N$BsConsole.dynamic(user.email),
                                  key: user.email + ("__" + String(idx))
                                });
                    }),
                  renderInput: (function (param) {
                      var resetValue = param.resetValue;
                      var onFocus = param.onFocus;
                      var onChange = param.onChange;
                      var onFocus$prime = Curry.__1(onFocus);
                      var onChange$prime = Curry.__1(onChange);
                      return React.createElement(React.Fragment, undefined, React.createElement("div", {
                                      className: container
                                    }, React.createElement(TextField.default, {
                                          placeholder: param.placeholder,
                                          value: param.value,
                                          color: "primary",
                                          variant: "filled",
                                          classes: textField,
                                          onChange: onChange$prime,
                                          onFocus: onFocus$prime
                                        }), React.createElement(Button.default, {
                                          variant: "text",
                                          color: "primary",
                                          disabled: recipient.trim() === "" || !Validator.isEmail(recipient),
                                          classes: button,
                                          onClick: (function (param) {
                                              if (!Belt_Array.some(notificationRecipients, (function (r) {
                                                        return r === recipient;
                                                      }))) {
                                                Curry._1(onAddRecipient, recipient);
                                              }
                                              Curry._1(setRecipient, (function (param) {
                                                      return "";
                                                    }));
                                              return Curry._1(resetValue, undefined);
                                            }),
                                          children: I18N$BsConsole.show(undefined, "Add recipient")
                                        })));
                    }),
                  key: "recipient"
                }), React.createElement("div", {
                  className: chipContainer
                }, notificationRecipients.length === 0 ? React.createElement(Chip.default, {
                        label: React.createElement("span", {
                              className: chipLabel
                            }, I18N$BsConsole.showSkip("all org admins")),
                        onDelete: (function (param) {
                            return Curry._1(onRemoveRecipient, recipient);
                          }),
                        variant: "outlined",
                        size: "small",
                        color: "primary",
                        classes: {
                          root: Css.style(/* :: */[
                                Css.marginRight(Css.rem(0.25)),
                                /* :: */[
                                  Css.marginBottom(Css.rem(0.25)),
                                  /* [] */0
                                ]
                              ])
                        }
                      }) : Belt_Array.mapWithIndex(notificationRecipients, (function (idx, recipient) {
                          return React.createElement(Chip.default, {
                                      label: React.createElement("span", {
                                            className: chipLabel
                                          }, I18N$BsConsole.showSkip(recipient)),
                                      onDelete: (function (param) {
                                          return Curry._1(onRemoveRecipient, recipient);
                                        }),
                                      variant: "outlined",
                                      size: "small",
                                      color: "primary",
                                      classes: {
                                        root: Css.style(/* :: */[
                                              Css.marginRight(Css.rem(0.25)),
                                              /* :: */[
                                                Css.marginBottom(Css.rem(0.25)),
                                                /* [] */0
                                              ]
                                            ])
                                      },
                                      key: "NotificationRecipientsInput" + (recipient + String(idx))
                                    });
                        }))));
}

var NotificationRecipientsInput = {
  Style: Style,
  make: DataSourcesConnectionForm$NotificationRecipientsInput
};

function addNotificationFields(name, areNotificationsAvailable, value, arr) {
  if (areNotificationsAvailable) {
    return Belt_Array.concat(arr, [/* tuple */[
                  name,
                  /* `Bool */[
                    737456202,
                    value
                  ]
                ]]);
  } else {
    return arr;
  }
}

function DataSourcesConnectionForm$ConnectionNotificationsInput(Props) {
  var users = Props.users;
  var token = Props.token;
  var serviceEndpoint = Props.serviceEndpoint;
  var sendNotifications = Props.sendNotifications;
  var notificationRecipients = Props.notificationRecipients;
  var areNotificationsAvailable = Props.areNotificationsAvailable;
  var handleChange = Props.handleChange;
  var handleRemove = Props.handleRemove;
  var handleAdd = Props.handleAdd;
  var handleSetAreNotificationsAvailable = Props.handleSetAreNotificationsAvailable;
  var onSuccess = function (serviceVersion) {
    return Curry._1(handleSetAreNotificationsAvailable, Semver.gte(serviceVersion, "2.2.0"));
  };
  var onFailure = function (_err) {
    return Curry._1(handleSetAreNotificationsAvailable, false);
  };
  React.useEffect((function () {
          var task = DataSourcesApi$BsConsole.getServiceVersion(serviceEndpoint);
          Task2$BsConsole.handle(token, task, undefined, (function (param) {
                  if (!param.tag) {
                    return Curry._1(onSuccess, param[0]);
                  }
                  var error = param[0];
                  return Curry._1(onFailure, error[0] === Task2$BsConsole.CoronerError ? error[1].message : (
                                error[0] === DataSourcesApi$BsConsole.ConnectorError ? error[1] : "Error: Something went wrong. This error has been reported to Backtrace."
                              ));
                }));
          
        }), ([]));
  if (areNotificationsAvailable) {
    return React.createElement(React.Fragment, undefined, React.createElement(BtSettings$BsConsole.FormSectionTitle.make, {
                    title: I18N$BsConsole.get(undefined, "Disabled connection notification")
                  }), React.createElement(FormControlLabel.default, {
                    label: I18N$BsConsole.get(undefined, "Receive notifications if your connection becomes disabled."),
                    control: React.createElement(Checkbox.default, {
                          checked: sendNotifications,
                          color: "primary",
                          onChange: (function ($$event) {
                              return Curry._1(handleChange, $$event.target.checked);
                            })
                        })
                  }), sendNotifications ? React.createElement(React.Fragment, undefined, React.createElement(BtSettings$BsConsole.FormSectionTitle.make, {
                          title: I18N$BsConsole.get(undefined, "Notification Recipients")
                        }), React.createElement(BtSettings$BsConsole.FormSectionLabel.make, {
                          label: I18N$BsConsole.show(undefined, "Recipients that will recieve notifications if your connection becomes disabled.")
                        }), React.createElement(DataSourcesConnectionForm$NotificationRecipientsInput, {
                          users: users,
                          onAddRecipient: Curry.__1(handleAdd),
                          onRemoveRecipient: Curry.__1(handleRemove),
                          notificationRecipients: notificationRecipients
                        })) : null);
  } else {
    return null;
  }
}

var ConnectionNotificationsInput = {
  addNotificationFields: addNotificationFields,
  make: DataSourcesConnectionForm$ConnectionNotificationsInput
};

var radioRoot = Css.style(/* :: */[
      Css.padding(Css.px(3)),
      /* :: */[
        Css.paddingLeft(Css.px(12)),
        /* :: */[
          Css.paddingRight(Css.px(12)),
          /* [] */0
        ]
      ]
    ]);

function reducer(state, action) {
  if (action.tag) {
    return {
            radioValue: state.radioValue,
            inputValue: action[0]
          };
  } else {
    return {
            radioValue: action[0],
            inputValue: state.inputValue
          };
  }
}

function initialState(defaultOptions, value) {
  return {
          radioValue: Belt_Array.map(defaultOptions, (function (param) {
                    return param[0];
                  })).includes(value) ? value : "Other",
          inputValue: Belt_Array.map(defaultOptions, (function (param) {
                    return param[0];
                  })).includes(value) ? "" : value
        };
}

function DataSourcesConnectionForm$TextFieldWithDefaults(Props) {
  var defaultOptions = Props.defaultOptions;
  var value = Props.value;
  var onChange = Props.onChange;
  var ariaLabel = Props.ariaLabel;
  var placeholderOpt = Props.placeholder;
  var otherLabelOpt = Props.otherLabel;
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : I18N$BsConsole.get(undefined, "https://my-server-url.com");
  var otherLabel = otherLabelOpt !== undefined ? otherLabelOpt : I18N$BsConsole.get(undefined, "Other");
  var match = React.useReducer(reducer, initialState(defaultOptions, value));
  var dispatch = match[1];
  var match$1 = match[0];
  var inputValue = match$1.inputValue;
  var radioValue = match$1.radioValue;
  var tmp = {
    value: radioValue,
    onChange: (function (e) {
        var radioButtonValue = e.target.value;
        Curry._1(dispatch, /* SetRadioValue */Block.__(0, [radioButtonValue]));
        return Curry._1(onChange, radioButtonValue === "Other" ? inputValue : radioButtonValue);
      }),
    classes: {
      root: Css.style(/* :: */[
            Css.marginBottom(Css.rem(0.5)),
            /* [] */0
          ])
    },
    children: null
  };
  if (ariaLabel !== undefined) {
    tmp.ariaLabel = Caml_option.valFromOption(ariaLabel);
  }
  return React.createElement(RadioGroup.default, tmp, Belt_Array.mapWithIndex(defaultOptions, (function (idx, param) {
                    return React.createElement(FormControlLabel.default, {
                                value: param[0],
                                label: param[1],
                                control: React.createElement(Radio.default, {
                                      color: "primary",
                                      classes: {
                                        root: radioRoot
                                      }
                                    }),
                                key: "TextFieldWithDefaults" + (value + String(idx))
                              });
                  })), React.createElement(FormControlLabel.default, {
                  value: "Other",
                  label: React.createElement(Row2$BsConsole.make, {
                        alignItems: /* center */98248149,
                        className: Css.style(/* :: */[
                              Css.minWidth(Css.px(450)),
                              /* [] */0
                            ]),
                        children: null
                      }, React.createElement("div", {
                            className: Css.style(/* :: */[
                                  Css.minWidth(Css.pct(25.0)),
                                  /* [] */0
                                ])
                          }, I18N$BsConsole.show(undefined, otherLabel)), React.createElement(TextField.default, {
                            disabled: radioValue !== "Other",
                            placeholder: placeholder,
                            value: inputValue,
                            color: "primary",
                            variant: "filled",
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.minWidth(Css.pct(100.0)),
                                    /* :: */[
                                      Css.marginLeft(Css.rem(0.5)),
                                      /* :: */[
                                        Css.selector("input", /* :: */[
                                              Css.important(Css.paddingTop(Css.px(10))),
                                              /* [] */0
                                            ]),
                                        /* [] */0
                                      ]
                                    ]
                                  ])
                            },
                            onChange: (function (e) {
                                var value = e.target.value;
                                Curry._1(dispatch, /* SetInputValue */Block.__(1, [value]));
                                return Curry._1(onChange, value);
                              })
                          })),
                  control: React.createElement(Radio.default, {
                        color: "primary",
                        classes: {
                          root: radioRoot
                        }
                      })
                }));
}

var TextFieldWithDefaults = {
  reducer: reducer,
  initialState: initialState,
  make: DataSourcesConnectionForm$TextFieldWithDefaults
};

var radioRoot$1 = Css.style(/* :: */[
      Css.padding(Css.px(3)),
      /* :: */[
        Css.paddingLeft(Css.px(12)),
        /* :: */[
          Css.paddingRight(Css.px(12)),
          /* [] */0
        ]
      ]
    ]);

function DataSourcesConnectionForm$AttachmentStrategy(Props) {
  var value = Props.value;
  var onChange = Props.onChange;
  var supportLink = Href$BsConsole.Support.make(undefined, /* ArticleSubmissionBuckets */6);
  return React.createElement(React.Fragment, undefined, React.createElement(BtSettings$BsConsole.FormSectionTitle.make, {
                  title: I18N$BsConsole.get(undefined, "Crash attachment storage")
                }), React.createElement(BtSettings$BsConsole.FormSectionLabel.make, {
                  label: I18N$BsConsole.show(undefined, "Choose whether you would like Backtrace to store crash attachment archives.")
                }), React.createElement(RadioGroup.default, {
                  ariaLabel: "attachment strategy",
                  value: DataSourcesApi$BsConsole.AttachmentStrategy.toString(value),
                  onChange: (function (e) {
                      return Curry._1(onChange, DataSourcesApi$BsConsole.AttachmentStrategy.fromString(e.target.value));
                    }),
                  classes: {
                    root: Css.style(/* :: */[
                          Css.marginBottom(Css.rem(0.5)),
                          /* [] */0
                        ])
                  },
                  children: null
                }, React.createElement(FormControlLabel.default, {
                      value: DataSourcesApi$BsConsole.AttachmentStrategy.toString(/* Full */0),
                      label: I18N$BsConsole.get(undefined, "Store all attachment archives in Backtrace"),
                      control: React.createElement(Radio.default, {
                            color: "primary",
                            classes: {
                              root: radioRoot$1
                            }
                          })
                    }), React.createElement(FormControlLabel.default, {
                      value: DataSourcesApi$BsConsole.AttachmentStrategy.toString(/* Ignore */1),
                      label: I18N$BsConsole.get(undefined, "Do not store attachment archives in Backtrace"),
                      control: React.createElement(Radio.default, {
                            color: "primary",
                            classes: {
                              root: radioRoot$1
                            }
                          })
                    }), React.createElement(FormControlLabel.default, {
                      value: "dynamic",
                      label: I18N$BsConsole.get(undefined, "Store attachments dynamically*"),
                      control: React.createElement(Radio.default, {
                            color: "primary",
                            classes: {
                              root: radioRoot$1
                            }
                          })
                    })), supportLink !== undefined ? React.createElement(Typography.default, {
                    variant: "caption",
                    classes: {
                      root: Css.style(/* :: */[
                            Css.marginBottom(Css.rem(1)),
                            /* [] */0
                          ])
                    },
                    children: null
                  }, I18N$BsConsole.show(undefined, "* - "), React.createElement("a", {
                        href: supportLink,
                        rel: "noopener noreferrer",
                        target: "_blank"
                      }, I18N$BsConsole.show(undefined, "learn more about storing attachments dynamically")), I18N$BsConsole.show(undefined, ".")) : null);
}

var AttachmentStrategy = {
  radioRoot: radioRoot$1,
  make: DataSourcesConnectionForm$AttachmentStrategy
};

var mockToken = "\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022";

function DataSourcesConnectionForm$ApiTokenFormInput(Props) {
  var placeholderOpt = Props.placeholder;
  var value = Props.value;
  var onChange = Props.onChange;
  var secretExists = Props.secretExists;
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : I18N$BsConsole.get(undefined, "Token");
  var match = React.useState((function () {
          return false;
        }));
  var setFocused = match[1];
  var showMockToken = secretExists && !Belt_Option.isSome(value);
  var onChange$1 = function ($$event) {
    var value = $$event.target.value;
    if (value === mockToken && showMockToken) {
      return ;
    } else {
      return Curry._1(onChange, $$event);
    }
  };
  var onFocus = function (_event) {
    return Curry._1(setFocused, (function (param) {
                  return true;
                }));
  };
  var onBlur = function (_event) {
    return Curry._1(setFocused, (function (param) {
                  return false;
                }));
  };
  return React.createElement(BtSettings$BsConsole.FormInput.make, {
              placeholder: placeholder,
              value: showMockToken && !match[0] ? mockToken : Belt_Option.getWithDefault(value, ""),
              onChange: onChange$1,
              onFocus: onFocus,
              onBlur: onBlur
            });
}

var ApiTokenFormInput = {
  mockToken: mockToken,
  make: DataSourcesConnectionForm$ApiTokenFormInput
};

var defaultRecapServerUrl = "https://ps4-product.recap.siedev.net/recap/rest/v1";

function isDefaultServer(serverUrl) {
  return serverUrl === defaultRecapServerUrl;
}

function fromServerUrl(serverUrl, value) {
  var usingDefaultServer = serverUrl === defaultRecapServerUrl;
  if (usingDefaultServer) {
    if (value !== undefined) {
      return /* SolrFilter */Block.__(0, [value]);
    } else {
      return ;
    }
  } else if (value !== undefined) {
    return /* ProjectId */Block.__(1, [value]);
  } else {
    return ;
  }
}

function fromConnection(connection) {
  var usingDefaultServer = connection.serverUrl === defaultRecapServerUrl;
  var match = connection.filter;
  var match$1 = connection.projectId;
  if (usingDefaultServer) {
    if (match !== undefined) {
      return /* SolrFilter */Block.__(0, [match]);
    } else {
      return ;
    }
  } else if (match$1 !== undefined) {
    return /* ProjectId */Block.__(1, [match$1]);
  } else {
    return ;
  }
}

function toValue(t) {
  if (t !== undefined) {
    return t[0];
  }
  
}

var solrPlaceholder = "(titleId:awesomeTitle1 OR titleId:awesomeTitle2 OR titleId:awesomeTitle3)";

var solrTitle = "Submission filter";

var solrLabel = "Solr query to filter crashes fetched from back office server.";

var projPlaceholder = "allTitles";

var projTitle = "Title ID";

var projLabel = "Sony Playstation Title ID";

function getPlaceholder(usingDefaultServer) {
  if (usingDefaultServer) {
    return solrPlaceholder;
  } else {
    return projPlaceholder;
  }
}

function getTitle(usingDefaultServer) {
  if (usingDefaultServer) {
    return solrTitle;
  } else {
    return projTitle;
  }
}

function getLabel(usingDefaultServer) {
  if (usingDefaultServer) {
    return solrLabel;
  } else {
    return projLabel;
  }
}

var $$Text = {
  solrPlaceholder: solrPlaceholder,
  solrTitle: solrTitle,
  solrLabel: solrLabel,
  projPlaceholder: projPlaceholder,
  projTitle: projTitle,
  projLabel: projLabel,
  getPlaceholder: getPlaceholder,
  getTitle: getTitle,
  getLabel: getLabel
};

var ProjectIdOrFilter = {
  fromServerUrl: fromServerUrl,
  fromConnection: fromConnection,
  toValue: toValue,
  $$Text: $$Text
};

function getInitialState(connection, coronerdAddress) {
  if (connection === undefined) {
    return {
            name: undefined,
            secret: undefined,
            serverUrl: defaultRecapServerUrl,
            projectIdOrFilter: fromServerUrl(defaultRecapServerUrl, undefined),
            coronerdAddress: coronerdAddress,
            attributeMapping: [],
            hasSubmitted: false,
            attachmentStrategy: /* Full */0,
            extendAttributes: true,
            ignoreSslCert: false,
            sendNotifications: true,
            notificationRecipients: [],
            areNotificationsAvailable: false
          };
  }
  var connectionNotifications = connection.connectionNotifications;
  var connectionNotifications$1 = connection.connectionNotifications;
  return {
          name: connection.name,
          secret: undefined,
          serverUrl: connection.serverUrl,
          projectIdOrFilter: fromConnection(connection),
          coronerdAddress: connection.coronerdAddress,
          attributeMapping: connection.attributeMapping,
          hasSubmitted: false,
          attachmentStrategy: connection.attachmentStrategy,
          extendAttributes: true,
          ignoreSslCert: connection.ignoreSslCert,
          sendNotifications: connectionNotifications !== undefined ? connectionNotifications.sendNotifications : true,
          notificationRecipients: connectionNotifications$1 !== undefined ? connectionNotifications$1.notificationRecipients : [],
          areNotificationsAvailable: false
        };
}

function reducer$1(state, action) {
  if (typeof action === "number") {
    return {
            name: state.name,
            secret: state.secret,
            serverUrl: state.serverUrl,
            projectIdOrFilter: state.projectIdOrFilter,
            coronerdAddress: state.coronerdAddress,
            attributeMapping: state.attributeMapping,
            hasSubmitted: true,
            attachmentStrategy: state.attachmentStrategy,
            extendAttributes: state.extendAttributes,
            ignoreSslCert: state.ignoreSslCert,
            sendNotifications: state.sendNotifications,
            notificationRecipients: state.notificationRecipients,
            areNotificationsAvailable: state.areNotificationsAvailable
          };
  }
  switch (action.tag | 0) {
    case /* SetAttachmentStrategy */0 :
        return {
                name: state.name,
                secret: state.secret,
                serverUrl: state.serverUrl,
                projectIdOrFilter: state.projectIdOrFilter,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: state.attributeMapping,
                hasSubmitted: state.hasSubmitted,
                attachmentStrategy: action[0],
                extendAttributes: state.extendAttributes,
                ignoreSslCert: state.ignoreSslCert,
                sendNotifications: state.sendNotifications,
                notificationRecipients: state.notificationRecipients,
                areNotificationsAvailable: state.areNotificationsAvailable
              };
    case /* AddMapping */1 :
        var newAttributeMapping = Belt_Array.concat(state.attributeMapping, [action[0]]);
        return {
                name: state.name,
                secret: state.secret,
                serverUrl: state.serverUrl,
                projectIdOrFilter: state.projectIdOrFilter,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: newAttributeMapping,
                hasSubmitted: state.hasSubmitted,
                attachmentStrategy: state.attachmentStrategy,
                extendAttributes: state.extendAttributes,
                ignoreSslCert: state.ignoreSslCert,
                sendNotifications: state.sendNotifications,
                notificationRecipients: state.notificationRecipients,
                areNotificationsAvailable: state.areNotificationsAvailable
              };
    case /* RemoveMapping */2 :
        var match = action[0];
        var mapV = match[1];
        var mapK = match[0];
        var newAttributeMapping$1 = Belt_Array.keep(state.attributeMapping, (function (param) {
                if (mapK !== param[0]) {
                  return mapV !== param[1];
                } else {
                  return false;
                }
              }));
        return {
                name: state.name,
                secret: state.secret,
                serverUrl: state.serverUrl,
                projectIdOrFilter: state.projectIdOrFilter,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: newAttributeMapping$1,
                hasSubmitted: state.hasSubmitted,
                attachmentStrategy: state.attachmentStrategy,
                extendAttributes: state.extendAttributes,
                ignoreSslCert: state.ignoreSslCert,
                sendNotifications: state.sendNotifications,
                notificationRecipients: state.notificationRecipients,
                areNotificationsAvailable: state.areNotificationsAvailable
              };
    case /* SetField */3 :
        var value = action[1];
        switch (action[0]) {
          case /* Name */0 :
              return {
                      name: value,
                      secret: state.secret,
                      serverUrl: state.serverUrl,
                      projectIdOrFilter: state.projectIdOrFilter,
                      coronerdAddress: state.coronerdAddress,
                      attributeMapping: state.attributeMapping,
                      hasSubmitted: state.hasSubmitted,
                      attachmentStrategy: state.attachmentStrategy,
                      extendAttributes: state.extendAttributes,
                      ignoreSslCert: state.ignoreSslCert,
                      sendNotifications: state.sendNotifications,
                      notificationRecipients: state.notificationRecipients,
                      areNotificationsAvailable: state.areNotificationsAvailable
                    };
          case /* Secret */1 :
              return {
                      name: state.name,
                      secret: value,
                      serverUrl: state.serverUrl,
                      projectIdOrFilter: state.projectIdOrFilter,
                      coronerdAddress: state.coronerdAddress,
                      attributeMapping: state.attributeMapping,
                      hasSubmitted: state.hasSubmitted,
                      attachmentStrategy: state.attachmentStrategy,
                      extendAttributes: state.extendAttributes,
                      ignoreSslCert: state.ignoreSslCert,
                      sendNotifications: state.sendNotifications,
                      notificationRecipients: state.notificationRecipients,
                      areNotificationsAvailable: state.areNotificationsAvailable
                    };
          case /* ServerUrl */2 :
              return {
                      name: state.name,
                      secret: state.secret,
                      serverUrl: value,
                      projectIdOrFilter: undefined,
                      coronerdAddress: state.coronerdAddress,
                      attributeMapping: state.attributeMapping,
                      hasSubmitted: state.hasSubmitted,
                      attachmentStrategy: state.attachmentStrategy,
                      extendAttributes: state.extendAttributes,
                      ignoreSslCert: state.ignoreSslCert,
                      sendNotifications: state.sendNotifications,
                      notificationRecipients: state.notificationRecipients,
                      areNotificationsAvailable: state.areNotificationsAvailable
                    };
          case /* CoronerdAddress */3 :
              return {
                      name: state.name,
                      secret: state.secret,
                      serverUrl: state.serverUrl,
                      projectIdOrFilter: state.projectIdOrFilter,
                      coronerdAddress: value,
                      attributeMapping: state.attributeMapping,
                      hasSubmitted: state.hasSubmitted,
                      attachmentStrategy: state.attachmentStrategy,
                      extendAttributes: state.extendAttributes,
                      ignoreSslCert: state.ignoreSslCert,
                      sendNotifications: state.sendNotifications,
                      notificationRecipients: state.notificationRecipients,
                      areNotificationsAvailable: state.areNotificationsAvailable
                    };
          case /* ProjectIdOrFilter */4 :
              var serverUrl = state.serverUrl;
              if (serverUrl !== undefined) {
                return {
                        name: state.name,
                        secret: state.secret,
                        serverUrl: state.serverUrl,
                        projectIdOrFilter: fromServerUrl(serverUrl, value),
                        coronerdAddress: state.coronerdAddress,
                        attributeMapping: state.attributeMapping,
                        hasSubmitted: state.hasSubmitted,
                        attachmentStrategy: state.attachmentStrategy,
                        extendAttributes: state.extendAttributes,
                        ignoreSslCert: state.ignoreSslCert,
                        sendNotifications: state.sendNotifications,
                        notificationRecipients: state.notificationRecipients,
                        areNotificationsAvailable: state.areNotificationsAvailable
                      };
              } else {
                return state;
              }
          
        }
    case /* SetExtendAttributes */4 :
        return {
                name: state.name,
                secret: state.secret,
                serverUrl: state.serverUrl,
                projectIdOrFilter: state.projectIdOrFilter,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: state.attributeMapping,
                hasSubmitted: state.hasSubmitted,
                attachmentStrategy: state.attachmentStrategy,
                extendAttributes: action[0],
                ignoreSslCert: state.ignoreSslCert,
                sendNotifications: state.sendNotifications,
                notificationRecipients: state.notificationRecipients,
                areNotificationsAvailable: state.areNotificationsAvailable
              };
    case /* SetIgnoreSslCert */5 :
        return {
                name: state.name,
                secret: state.secret,
                serverUrl: state.serverUrl,
                projectIdOrFilter: state.projectIdOrFilter,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: state.attributeMapping,
                hasSubmitted: state.hasSubmitted,
                attachmentStrategy: state.attachmentStrategy,
                extendAttributes: state.extendAttributes,
                ignoreSslCert: action[0],
                sendNotifications: state.sendNotifications,
                notificationRecipients: state.notificationRecipients,
                areNotificationsAvailable: state.areNotificationsAvailable
              };
    case /* SetSendNotifications */6 :
        return {
                name: state.name,
                secret: state.secret,
                serverUrl: state.serverUrl,
                projectIdOrFilter: state.projectIdOrFilter,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: state.attributeMapping,
                hasSubmitted: state.hasSubmitted,
                attachmentStrategy: state.attachmentStrategy,
                extendAttributes: state.extendAttributes,
                ignoreSslCert: state.ignoreSslCert,
                sendNotifications: action[0],
                notificationRecipients: state.notificationRecipients,
                areNotificationsAvailable: state.areNotificationsAvailable
              };
    case /* AddNotificationRecipient */7 :
        var newNotificationRecipients = Belt_Array.concat(state.notificationRecipients, [action[0]]);
        return {
                name: state.name,
                secret: state.secret,
                serverUrl: state.serverUrl,
                projectIdOrFilter: state.projectIdOrFilter,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: state.attributeMapping,
                hasSubmitted: state.hasSubmitted,
                attachmentStrategy: state.attachmentStrategy,
                extendAttributes: state.extendAttributes,
                ignoreSslCert: state.ignoreSslCert,
                sendNotifications: state.sendNotifications,
                notificationRecipients: newNotificationRecipients,
                areNotificationsAvailable: state.areNotificationsAvailable
              };
    case /* RemoveNotificationRecipient */8 :
        var recipient = action[0];
        var newNotificationRecipients$1 = Belt_Array.keep(state.notificationRecipients, (function (r) {
                return r !== recipient;
              }));
        return {
                name: state.name,
                secret: state.secret,
                serverUrl: state.serverUrl,
                projectIdOrFilter: state.projectIdOrFilter,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: state.attributeMapping,
                hasSubmitted: state.hasSubmitted,
                attachmentStrategy: state.attachmentStrategy,
                extendAttributes: state.extendAttributes,
                ignoreSslCert: state.ignoreSslCert,
                sendNotifications: state.sendNotifications,
                notificationRecipients: newNotificationRecipients$1,
                areNotificationsAvailable: state.areNotificationsAvailable
              };
    case /* SetAreNotificationsAvailable */9 :
        return {
                name: state.name,
                secret: state.secret,
                serverUrl: state.serverUrl,
                projectIdOrFilter: state.projectIdOrFilter,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: state.attributeMapping,
                hasSubmitted: state.hasSubmitted,
                attachmentStrategy: state.attachmentStrategy,
                extendAttributes: state.extendAttributes,
                ignoreSslCert: state.ignoreSslCert,
                sendNotifications: state.sendNotifications,
                notificationRecipients: state.notificationRecipients,
                areNotificationsAvailable: action[0]
              };
    
  }
}

function DataSourcesConnectionForm$Playstation4Form(Props) {
  var users = Props.users;
  var token = Props.token;
  var projectName = Props.projectName;
  var serviceEndpoint = Props.serviceEndpoint;
  var onSuccess = Props.onSuccess;
  var onFailure = Props.onFailure;
  var connection = Props.connection;
  var coronerdAddress = Props.coronerdAddress;
  var onClose = Props.onClose;
  var match = React.useReducer(reducer$1, getInitialState(connection, coronerdAddress));
  var state = match[0];
  var areNotificationsAvailable = state.areNotificationsAvailable;
  var notificationRecipients = state.notificationRecipients;
  var sendNotifications = state.sendNotifications;
  var ignoreSslCert = state.ignoreSslCert;
  var extendAttributes = state.extendAttributes;
  var hasSubmitted = state.hasSubmitted;
  var coronerdAddress$1 = state.coronerdAddress;
  var projectIdOrFilter = state.projectIdOrFilter;
  var serverUrl = state.serverUrl;
  var dispatch = match[1];
  var setInputValue = function (field, e) {
    var stringValue = e.target.value;
    var value = stringValue === "" ? undefined : stringValue;
    return Curry._1(dispatch, /* SetField */Block.__(3, [
                  field,
                  value
                ]));
  };
  var getHelperText = function (value) {
    if (hasSubmitted && value === undefined) {
      return "required";
    } else {
      return "";
    }
  };
  var addStringVal = function (name, value, arr) {
    if (value !== undefined) {
      return Belt_Array.concat(arr, [/* tuple */[
                    name,
                    /* `String */[
                      -976970511,
                      Caml_option.valFromOption(value)
                    ]
                  ]]);
    } else {
      return arr;
    }
  };
  var getFormBody = function (state) {
    var arg = [
      /* tuple */[
        "type",
        /* `Int */[
          3654863,
          1
        ]
      ],
      /* tuple */[
        "attachmentStrategy",
        /* `Int */[
          3654863,
          DataSourcesApi$BsConsole.AttachmentStrategy.fromVariant(state.attachmentStrategy)
        ]
      ],
      /* tuple */[
        "extendAttributes",
        /* `Bool */[
          737456202,
          extendAttributes
        ]
      ],
      /* tuple */[
        "ignoreSslCert",
        /* `Bool */[
          737456202,
          ignoreSslCert
        ]
      ]
    ];
    var func = function (param, param$1) {
      return addStringVal("name", param, param$1);
    };
    var arg$1 = Curry._2(func, state.name, arg);
    var func$1 = function (param, param$1) {
      return addStringVal("secret", param, param$1);
    };
    var arg$2 = Curry._2(func$1, state.secret, arg$1);
    var func$2 = function (param, param$1) {
      return addStringVal("serverUrl", param, param$1);
    };
    var arr = Curry._2(func$2, state.serverUrl, arg$2);
    var match = state.projectIdOrFilter;
    var arg$3;
    if (match !== undefined) {
      if (match.tag) {
        var func$3 = function (param, param$1) {
          return addStringVal("projectId", param, param$1);
        };
        arg$3 = Curry._2(func$3, match[0], arr);
      } else {
        var func$4 = function (param, param$1) {
          return addStringVal("filter", param, param$1);
        };
        arg$3 = Curry._2(func$4, match[0], arr);
      }
    } else {
      arg$3 = arr;
    }
    var func$5 = function (param, param$1) {
      return addStringVal("coronerdAddress", param, param$1);
    };
    var arg$4 = Curry._2(func$5, state.coronerdAddress, arg$3);
    var arg$5 = DataSourcesApi$BsConsole.prepareFormBody(Curry._1((function (param) {
                    return (function (param$1) {
                        return (function (param$2) {
                            return addNotificationFields(param, param$1, param$2, arg$4);
                          });
                      });
                  })("sendConnectionDisabledNotification")(areNotificationsAvailable), sendNotifications));
    var arg$6 = (function (param) {
          return DataSourcesApi$BsConsole.addAttributeMappingsInForm(param, arg$5);
        })(state.attributeMapping);
    return Curry._1((function (param) {
                      var func = function (param$1, param$2, param$3) {
                        return DataSourcesApi$BsConsole.addNotificationRecipientsInForm(param, param$1, param$2, param$3);
                      };
                      return (function (param) {
                          var func$1 = Curry._1(func, param);
                          return (function (param) {
                              return Curry._2(func$1, param, arg$6);
                            });
                        });
                    })(notificationRecipients)(sendNotifications), areNotificationsAvailable);
  };
  var secretExists = connection !== undefined ? connection.secretExists : false;
  var match$1 = React.useState((function () {
          return false;
        }));
  var setLoading = match$1[1];
  var usingDefaultServer = serverUrl !== undefined ? serverUrl === defaultRecapServerUrl : true;
  return React.createElement(DataSourcesConnectionForm$FormContainer, {
              title: I18N$BsConsole.get(undefined, "PlayStation 4 Integration"),
              logoSrc: DataSourcesApi$BsConsole.playstation4LogoInfo[0],
              logoAlt: DataSourcesApi$BsConsole.playstation4LogoInfo[1],
              logoClassName: DataSourcesApi$BsConsole.playstation4LogoInfo[2],
              disabled: connection !== undefined ? false : (
                  serverUrl !== undefined ? coronerdAddress$1 === undefined : true
                ),
              loading: match$1[0],
              onSave: (function (param) {
                  Curry._1(setLoading, (function (param) {
                          return true;
                        }));
                  Curry._1(dispatch, /* Send */0);
                  var formBody = getFormBody(state);
                  var connectionTask;
                  if (connection !== undefined) {
                    var partial_arg = connection.id;
                    connectionTask = (function (param) {
                        return DataSourcesApi$BsConsole.put(partial_arg, serviceEndpoint, param);
                      });
                  } else {
                    connectionTask = (function (param) {
                        return DataSourcesApi$BsConsole.post(projectName, serviceEndpoint, param);
                      });
                  }
                  var handleOnFailure = function (err) {
                    Curry._1(setLoading, (function (param) {
                            return false;
                          }));
                    return Curry._1(onFailure, err);
                  };
                  var arg = Curry._1(connectionTask, formBody);
                  return Curry._2((function (param) {
                                  return (function (param$1, param$2) {
                                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                    });
                                })(token), undefined, (function (param) {
                                return responseCb(onSuccess, handleOnFailure, param);
                              }));
                }),
              onClose: onClose,
              children: null
            }, React.createElement(FormControl.default, {
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* [] */0
                      ]),
                  children: null
                }, React.createElement(BtSettings$BsConsole.FormInput.make, {
                      placeholder: I18N$BsConsole.get(undefined, "my-connection-name"),
                      title: I18N$BsConsole.get(undefined, "Connection name"),
                      value: Belt_Option.getWithDefault(state.name, ""),
                      label: I18N$BsConsole.show(undefined, "Name used to identify the connection."),
                      onChange: (function (param) {
                          return setInputValue(/* Name */0, param);
                        })
                    }), React.createElement(BtSettings$BsConsole.FormSectionTitle.make, {
                      title: I18N$BsConsole.get(undefined, "Server URL")
                    }), React.createElement(BtSettings$BsConsole.FormSectionLabel.make, {
                      label: I18N$BsConsole.show(undefined, "Recap server endpoint")
                    }), React.createElement(DataSourcesConnectionForm$TextFieldWithDefaults, {
                      defaultOptions: [/* tuple */[
                          defaultRecapServerUrl,
                          defaultRecapServerUrl
                        ]],
                      value: Belt_Option.getWithDefault(serverUrl, ""),
                      onChange: (function (value) {
                          return Curry._1(dispatch, /* SetField */Block.__(3, [
                                        /* ServerUrl */2,
                                        value
                                      ]));
                        }),
                      ariaLabel: "service url",
                      placeholder: "https://ps4.your-studio.com/recap/rest",
                      otherLabel: I18N$BsConsole.get(undefined, "Other/Self Hosted")
                    }), React.createElement(BtSettings$BsConsole.FormSectionTitle.make, {
                      title: I18N$BsConsole.get(undefined, "API token")
                    }), React.createElement(BtSettings$BsConsole.FormSectionLabel.make, {
                      label: I18N$BsConsole.show(undefined, "API token for your recap server")
                    }), React.createElement(DataSourcesConnectionForm$ApiTokenFormInput, {
                      value: state.secret,
                      onChange: (function (param) {
                          return setInputValue(/* Secret */1, param);
                        }),
                      secretExists: secretExists
                    }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                      placeholder: I18N$BsConsole.get(undefined, "Submission URL"),
                      title: I18N$BsConsole.get(undefined, "Submission URL"),
                      value: Belt_Option.getWithDefault(coronerdAddress$1, ""),
                      label: I18N$BsConsole.show(undefined, "The URL used to submit errors to your Backtrace instance (e.g. https://submit.backtrace.io/{your-subdomain}/{submission-token}/json)."),
                      onChange: (function (param) {
                          return setInputValue(/* CoronerdAddress */3, param);
                        }),
                      error: hasSubmitted && coronerdAddress$1 === undefined,
                      helperText: getHelperText(coronerdAddress$1)
                    }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                      placeholder: I18N$BsConsole.get(undefined, usingDefaultServer ? solrPlaceholder : projPlaceholder),
                      title: I18N$BsConsole.get(undefined, usingDefaultServer ? solrTitle : projTitle),
                      value: Belt_Option.getWithDefault(toValue(projectIdOrFilter), ""),
                      label: I18N$BsConsole.show(undefined, usingDefaultServer ? solrLabel : projLabel),
                      onChange: (function (param) {
                          return setInputValue(/* ProjectIdOrFilter */4, param);
                        }),
                      error: hasSubmitted && toValue(projectIdOrFilter) === undefined,
                      helperText: getHelperText(toValue(projectIdOrFilter))
                    }), React.createElement(DataSourcesConnectionForm$AttachmentStrategy, {
                      value: state.attachmentStrategy,
                      onChange: (function (value) {
                          return Curry._1(dispatch, /* SetAttachmentStrategy */Block.__(0, [value]));
                        })
                    }), React.createElement(BtSettings$BsConsole.FormSectionTitle.make, {
                      title: I18N$BsConsole.get(undefined, "Extend attributes")
                    }), React.createElement(FormControlLabel.default, {
                      label: I18N$BsConsole.get(undefined, "Analyze and extend attributes from ORBISDMP files."),
                      control: React.createElement(Checkbox.default, {
                            checked: extendAttributes,
                            color: "primary",
                            onChange: (function ($$event) {
                                var value = $$event.target.checked;
                                return Curry._1(dispatch, /* SetExtendAttributes */Block.__(4, [value]));
                              })
                          })
                    }), React.createElement(BtSettings$BsConsole.FormSectionTitle.make, {
                      title: I18N$BsConsole.get(undefined, "Enable self signed certificates for recap server")
                    }), React.createElement(FormControlLabel.default, {
                      label: I18N$BsConsole.get(undefined, "Ignore SSL certificate validation."),
                      control: React.createElement(Checkbox.default, {
                            checked: ignoreSslCert,
                            color: "primary",
                            onChange: (function ($$event) {
                                var value = $$event.target.checked;
                                return Curry._1(dispatch, /* SetIgnoreSslCert */Block.__(5, [value]));
                              })
                          })
                    }), React.createElement(BtSettings$BsConsole.FormSectionTitle.make, {
                      title: I18N$BsConsole.get(undefined, "Attribute name mapping")
                    }), React.createElement(BtSettings$BsConsole.FormSectionLabel.make, {
                      label: I18N$BsConsole.show(undefined, "Rename attributes from your PlayStation 4 errors before they enter Backtrace storage.")
                    }), React.createElement(DataSourcesConnectionForm$AttributeMappingInput, {
                      onAddMapping: (function (from, to_) {
                          return Curry._1(dispatch, /* AddMapping */Block.__(1, [/* tuple */[
                                          from,
                                          to_
                                        ]]));
                        }),
                      onRemoveMapping: (function (from, to_) {
                          return Curry._1(dispatch, /* RemoveMapping */Block.__(2, [/* tuple */[
                                          from,
                                          to_
                                        ]]));
                        }),
                      attributeMapping: state.attributeMapping
                    }), React.createElement(DataSourcesConnectionForm$ConnectionNotificationsInput, {
                      users: users,
                      token: token,
                      serviceEndpoint: serviceEndpoint,
                      sendNotifications: sendNotifications,
                      notificationRecipients: notificationRecipients,
                      areNotificationsAvailable: areNotificationsAvailable,
                      handleChange: (function (value) {
                          return Curry._1(dispatch, /* SetSendNotifications */Block.__(6, [value]));
                        }),
                      handleRemove: (function (recipient) {
                          return Curry._1(dispatch, /* RemoveNotificationRecipient */Block.__(8, [recipient]));
                        }),
                      handleAdd: (function (recipient) {
                          return Curry._1(dispatch, /* AddNotificationRecipient */Block.__(7, [recipient]));
                        }),
                      handleSetAreNotificationsAvailable: (function (value) {
                          return Curry._1(dispatch, /* SetAreNotificationsAvailable */Block.__(9, [value]));
                        })
                    })), IdentityVariant$BsConsole.current >= 2 ? null : React.createElement(Typography.default, {
                    variant: "body1",
                    classes: {
                      root: Css.style(/* :: */[
                            Css.marginBottom(Css.rem(0.5)),
                            /* [] */0
                          ])
                    },
                    children: null
                  }, React.createElement("span", undefined, I18N$BsConsole.show(undefined, "For more information please contact ")), React.createElement("a", {
                        href: "mailto:" + Email$BsConsole.support,
                        rel: "noreferrer noopener"
                      }, I18N$BsConsole.show(undefined, "customer support")), React.createElement("span", undefined, I18N$BsConsole.showSkip("."))));
}

var Playstation4Form = {
  defaultRecapServerUrl: defaultRecapServerUrl,
  isDefaultServer: isDefaultServer,
  ProjectIdOrFilter: ProjectIdOrFilter,
  getInitialState: getInitialState,
  reducer: reducer$1,
  make: DataSourcesConnectionForm$Playstation4Form
};

var serverUrl = "https://s2s.np.playstation.net";

var defaultCrashServers = [/* tuple */[
    serverUrl,
    serverUrl
  ]];

function isDefaultServer$1(serverUrl) {
  return Belt_Array.keep(defaultCrashServers, (function (param) {
                return param[0] === serverUrl;
              })).length !== 0;
}

function fromServerUrl$1(serverUrl, value) {
  var usingDefaultServer = isDefaultServer$1(serverUrl);
  if (usingDefaultServer) {
    if (value !== undefined) {
      return /* SolrFilter */Block.__(0, [value]);
    } else {
      return ;
    }
  } else if (value !== undefined) {
    return /* ProjectId */Block.__(1, [value]);
  } else {
    return ;
  }
}

function fromConnection$1(connection) {
  var usingDefaultServer = isDefaultServer$1(connection.serverUrl);
  var match = connection.filter;
  var match$1 = connection.projectId;
  if (usingDefaultServer) {
    if (match !== undefined) {
      return /* SolrFilter */Block.__(0, [match]);
    } else {
      return ;
    }
  } else if (match$1 !== undefined) {
    return /* ProjectId */Block.__(1, [match$1]);
  } else {
    return ;
  }
}

function toValue$1(t) {
  if (t !== undefined) {
    return t[0];
  }
  
}

var solrPlaceholder$1 = "(titleId:awesomeTitle1 OR titleId:awesomeTitle2 OR titleId:awesomeTitle3)";

var solrTitle$1 = "Submission filter";

var solrLabel$1 = "Solr query to filter crashes fetched from back office server.";

var projPlaceholder$1 = "allTitles";

var projTitle$1 = "Title ID";

var projLabel$1 = "Sony Playstation Title ID";

function getPlaceholder$1(usingDefaultServer) {
  if (usingDefaultServer) {
    return solrPlaceholder$1;
  } else {
    return projPlaceholder$1;
  }
}

function getTitle$1(usingDefaultServer) {
  if (usingDefaultServer) {
    return solrTitle$1;
  } else {
    return projTitle$1;
  }
}

function getLabel$1(usingDefaultServer) {
  if (usingDefaultServer) {
    return solrLabel$1;
  } else {
    return projLabel$1;
  }
}

var $$Text$1 = {
  solrPlaceholder: solrPlaceholder$1,
  solrTitle: solrTitle$1,
  solrLabel: solrLabel$1,
  projPlaceholder: projPlaceholder$1,
  projTitle: projTitle$1,
  projLabel: projLabel$1,
  getPlaceholder: getPlaceholder$1,
  getTitle: getTitle$1,
  getLabel: getLabel$1
};

var ProjectIdOrFilter$1 = {
  fromServerUrl: fromServerUrl$1,
  fromConnection: fromConnection$1,
  toValue: toValue$1,
  $$Text: $$Text$1
};

function getInitialState$1(connection, coronerdAddress) {
  if (connection !== undefined) {
    var connectionNotifications = connection.connectionNotifications;
    var connectionNotifications$1 = connection.connectionNotifications;
    return {
            name: connection.name,
            projectIdOrFilter: fromConnection$1(connection),
            user: connection.user,
            secret: undefined,
            serverUrl: connection.serverUrl,
            coronerdAddress: connection.coronerdAddress,
            attributeMapping: connection.attributeMapping,
            hasSubmitted: false,
            attachmentStrategy: connection.attachmentStrategy,
            extendAttributes: true,
            ignoreSslCert: connection.ignoreSslCert,
            sendNotifications: connectionNotifications !== undefined ? connectionNotifications.sendNotifications : true,
            notificationRecipients: connectionNotifications$1 !== undefined ? connectionNotifications$1.notificationRecipients : [],
            areNotificationsAvailable: false
          };
  }
  var serverUrl = "https://s2s.np.playstation.net";
  return {
          name: undefined,
          projectIdOrFilter: fromServerUrl$1(serverUrl, undefined),
          user: undefined,
          secret: undefined,
          serverUrl: serverUrl,
          coronerdAddress: coronerdAddress,
          attributeMapping: [],
          hasSubmitted: false,
          attachmentStrategy: /* Full */0,
          extendAttributes: true,
          ignoreSslCert: false,
          sendNotifications: true,
          notificationRecipients: [],
          areNotificationsAvailable: false
        };
}

function reducer$2(state, action) {
  if (typeof action === "number") {
    return {
            name: state.name,
            projectIdOrFilter: state.projectIdOrFilter,
            user: state.user,
            secret: state.secret,
            serverUrl: state.serverUrl,
            coronerdAddress: state.coronerdAddress,
            attributeMapping: state.attributeMapping,
            hasSubmitted: true,
            attachmentStrategy: state.attachmentStrategy,
            extendAttributes: state.extendAttributes,
            ignoreSslCert: state.ignoreSslCert,
            sendNotifications: state.sendNotifications,
            notificationRecipients: state.notificationRecipients,
            areNotificationsAvailable: state.areNotificationsAvailable
          };
  }
  switch (action.tag | 0) {
    case /* SetAttachmentStrategy */0 :
        return {
                name: state.name,
                projectIdOrFilter: state.projectIdOrFilter,
                user: state.user,
                secret: state.secret,
                serverUrl: state.serverUrl,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: state.attributeMapping,
                hasSubmitted: state.hasSubmitted,
                attachmentStrategy: action[0],
                extendAttributes: state.extendAttributes,
                ignoreSslCert: state.ignoreSslCert,
                sendNotifications: state.sendNotifications,
                notificationRecipients: state.notificationRecipients,
                areNotificationsAvailable: state.areNotificationsAvailable
              };
    case /* AddMapping */1 :
        var newAttributeMapping = Belt_Array.concat(state.attributeMapping, [action[0]]);
        return {
                name: state.name,
                projectIdOrFilter: state.projectIdOrFilter,
                user: state.user,
                secret: state.secret,
                serverUrl: state.serverUrl,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: newAttributeMapping,
                hasSubmitted: state.hasSubmitted,
                attachmentStrategy: state.attachmentStrategy,
                extendAttributes: state.extendAttributes,
                ignoreSslCert: state.ignoreSslCert,
                sendNotifications: state.sendNotifications,
                notificationRecipients: state.notificationRecipients,
                areNotificationsAvailable: state.areNotificationsAvailable
              };
    case /* RemoveMapping */2 :
        var match = action[0];
        var mapV = match[1];
        var mapK = match[0];
        var newAttributeMapping$1 = Belt_Array.keep(state.attributeMapping, (function (param) {
                if (mapK !== param[0]) {
                  return mapV !== param[1];
                } else {
                  return false;
                }
              }));
        return {
                name: state.name,
                projectIdOrFilter: state.projectIdOrFilter,
                user: state.user,
                secret: state.secret,
                serverUrl: state.serverUrl,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: newAttributeMapping$1,
                hasSubmitted: state.hasSubmitted,
                attachmentStrategy: state.attachmentStrategy,
                extendAttributes: state.extendAttributes,
                ignoreSslCert: state.ignoreSslCert,
                sendNotifications: state.sendNotifications,
                notificationRecipients: state.notificationRecipients,
                areNotificationsAvailable: state.areNotificationsAvailable
              };
    case /* SetField */3 :
        var value = action[1];
        switch (action[0]) {
          case /* Name */0 :
              return {
                      name: value,
                      projectIdOrFilter: state.projectIdOrFilter,
                      user: state.user,
                      secret: state.secret,
                      serverUrl: state.serverUrl,
                      coronerdAddress: state.coronerdAddress,
                      attributeMapping: state.attributeMapping,
                      hasSubmitted: state.hasSubmitted,
                      attachmentStrategy: state.attachmentStrategy,
                      extendAttributes: state.extendAttributes,
                      ignoreSslCert: state.ignoreSslCert,
                      sendNotifications: state.sendNotifications,
                      notificationRecipients: state.notificationRecipients,
                      areNotificationsAvailable: state.areNotificationsAvailable
                    };
          case /* User */1 :
              return {
                      name: state.name,
                      projectIdOrFilter: state.projectIdOrFilter,
                      user: value,
                      secret: state.secret,
                      serverUrl: state.serverUrl,
                      coronerdAddress: state.coronerdAddress,
                      attributeMapping: state.attributeMapping,
                      hasSubmitted: state.hasSubmitted,
                      attachmentStrategy: state.attachmentStrategy,
                      extendAttributes: state.extendAttributes,
                      ignoreSslCert: state.ignoreSslCert,
                      sendNotifications: state.sendNotifications,
                      notificationRecipients: state.notificationRecipients,
                      areNotificationsAvailable: state.areNotificationsAvailable
                    };
          case /* Secret */2 :
              return {
                      name: state.name,
                      projectIdOrFilter: state.projectIdOrFilter,
                      user: state.user,
                      secret: value,
                      serverUrl: state.serverUrl,
                      coronerdAddress: state.coronerdAddress,
                      attributeMapping: state.attributeMapping,
                      hasSubmitted: state.hasSubmitted,
                      attachmentStrategy: state.attachmentStrategy,
                      extendAttributes: state.extendAttributes,
                      ignoreSslCert: state.ignoreSslCert,
                      sendNotifications: state.sendNotifications,
                      notificationRecipients: state.notificationRecipients,
                      areNotificationsAvailable: state.areNotificationsAvailable
                    };
          case /* ServerUrl */3 :
              return {
                      name: state.name,
                      projectIdOrFilter: undefined,
                      user: state.user,
                      secret: state.secret,
                      serverUrl: value,
                      coronerdAddress: state.coronerdAddress,
                      attributeMapping: state.attributeMapping,
                      hasSubmitted: state.hasSubmitted,
                      attachmentStrategy: state.attachmentStrategy,
                      extendAttributes: state.extendAttributes,
                      ignoreSslCert: state.ignoreSslCert,
                      sendNotifications: state.sendNotifications,
                      notificationRecipients: state.notificationRecipients,
                      areNotificationsAvailable: state.areNotificationsAvailable
                    };
          case /* CoronerdAddress */4 :
              return {
                      name: state.name,
                      projectIdOrFilter: state.projectIdOrFilter,
                      user: state.user,
                      secret: state.secret,
                      serverUrl: state.serverUrl,
                      coronerdAddress: value,
                      attributeMapping: state.attributeMapping,
                      hasSubmitted: state.hasSubmitted,
                      attachmentStrategy: state.attachmentStrategy,
                      extendAttributes: state.extendAttributes,
                      ignoreSslCert: state.ignoreSslCert,
                      sendNotifications: state.sendNotifications,
                      notificationRecipients: state.notificationRecipients,
                      areNotificationsAvailable: state.areNotificationsAvailable
                    };
          case /* ProjectIdOrFilter */5 :
              var serverUrl = state.serverUrl;
              if (serverUrl !== undefined) {
                return {
                        name: state.name,
                        projectIdOrFilter: fromServerUrl$1(serverUrl, value),
                        user: state.user,
                        secret: state.secret,
                        serverUrl: state.serverUrl,
                        coronerdAddress: state.coronerdAddress,
                        attributeMapping: state.attributeMapping,
                        hasSubmitted: state.hasSubmitted,
                        attachmentStrategy: state.attachmentStrategy,
                        extendAttributes: state.extendAttributes,
                        ignoreSslCert: state.ignoreSslCert,
                        sendNotifications: state.sendNotifications,
                        notificationRecipients: state.notificationRecipients,
                        areNotificationsAvailable: state.areNotificationsAvailable
                      };
              } else {
                return state;
              }
          
        }
    case /* SetExtendAttributes */4 :
        return {
                name: state.name,
                projectIdOrFilter: state.projectIdOrFilter,
                user: state.user,
                secret: state.secret,
                serverUrl: state.serverUrl,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: state.attributeMapping,
                hasSubmitted: state.hasSubmitted,
                attachmentStrategy: state.attachmentStrategy,
                extendAttributes: action[0],
                ignoreSslCert: state.ignoreSslCert,
                sendNotifications: state.sendNotifications,
                notificationRecipients: state.notificationRecipients,
                areNotificationsAvailable: state.areNotificationsAvailable
              };
    case /* SetIgnoreSslCert */5 :
        return {
                name: state.name,
                projectIdOrFilter: state.projectIdOrFilter,
                user: state.user,
                secret: state.secret,
                serverUrl: state.serverUrl,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: state.attributeMapping,
                hasSubmitted: state.hasSubmitted,
                attachmentStrategy: state.attachmentStrategy,
                extendAttributes: state.extendAttributes,
                ignoreSslCert: action[0],
                sendNotifications: state.sendNotifications,
                notificationRecipients: state.notificationRecipients,
                areNotificationsAvailable: state.areNotificationsAvailable
              };
    case /* SetSendNotifications */6 :
        return {
                name: state.name,
                projectIdOrFilter: state.projectIdOrFilter,
                user: state.user,
                secret: state.secret,
                serverUrl: state.serverUrl,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: state.attributeMapping,
                hasSubmitted: state.hasSubmitted,
                attachmentStrategy: state.attachmentStrategy,
                extendAttributes: state.extendAttributes,
                ignoreSslCert: state.ignoreSslCert,
                sendNotifications: action[0],
                notificationRecipients: state.notificationRecipients,
                areNotificationsAvailable: state.areNotificationsAvailable
              };
    case /* AddNotificationRecipient */7 :
        var newNotificationRecipients = Belt_Array.concat(state.notificationRecipients, [action[0]]);
        return {
                name: state.name,
                projectIdOrFilter: state.projectIdOrFilter,
                user: state.user,
                secret: state.secret,
                serverUrl: state.serverUrl,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: state.attributeMapping,
                hasSubmitted: state.hasSubmitted,
                attachmentStrategy: state.attachmentStrategy,
                extendAttributes: state.extendAttributes,
                ignoreSslCert: state.ignoreSslCert,
                sendNotifications: state.sendNotifications,
                notificationRecipients: newNotificationRecipients,
                areNotificationsAvailable: state.areNotificationsAvailable
              };
    case /* RemoveNotificationRecipient */8 :
        var recipient = action[0];
        var newNotificationRecipients$1 = Belt_Array.keep(state.notificationRecipients, (function (r) {
                return r !== recipient;
              }));
        return {
                name: state.name,
                projectIdOrFilter: state.projectIdOrFilter,
                user: state.user,
                secret: state.secret,
                serverUrl: state.serverUrl,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: state.attributeMapping,
                hasSubmitted: state.hasSubmitted,
                attachmentStrategy: state.attachmentStrategy,
                extendAttributes: state.extendAttributes,
                ignoreSslCert: state.ignoreSslCert,
                sendNotifications: state.sendNotifications,
                notificationRecipients: newNotificationRecipients$1,
                areNotificationsAvailable: state.areNotificationsAvailable
              };
    case /* SetAreNotificationsAvailable */9 :
        return {
                name: state.name,
                projectIdOrFilter: state.projectIdOrFilter,
                user: state.user,
                secret: state.secret,
                serverUrl: state.serverUrl,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: state.attributeMapping,
                hasSubmitted: state.hasSubmitted,
                attachmentStrategy: state.attachmentStrategy,
                extendAttributes: state.extendAttributes,
                ignoreSslCert: state.ignoreSslCert,
                sendNotifications: state.sendNotifications,
                notificationRecipients: state.notificationRecipients,
                areNotificationsAvailable: action[0]
              };
    
  }
}

function DataSourcesConnectionForm$Playstation5Form(Props) {
  var users = Props.users;
  var token = Props.token;
  var projectName = Props.projectName;
  var serviceEndpoint = Props.serviceEndpoint;
  var onSuccess = Props.onSuccess;
  var onFailure = Props.onFailure;
  var connection = Props.connection;
  var coronerdAddress = Props.coronerdAddress;
  var onClose = Props.onClose;
  var match = React.useReducer(reducer$2, getInitialState$1(connection, coronerdAddress));
  var state = match[0];
  var areNotificationsAvailable = state.areNotificationsAvailable;
  var notificationRecipients = state.notificationRecipients;
  var sendNotifications = state.sendNotifications;
  var ignoreSslCert = state.ignoreSslCert;
  var extendAttributes = state.extendAttributes;
  var hasSubmitted = state.hasSubmitted;
  var coronerdAddress$1 = state.coronerdAddress;
  var serverUrl = state.serverUrl;
  var user = state.user;
  var projectIdOrFilter = state.projectIdOrFilter;
  var dispatch = match[1];
  var usingDefaultServer = serverUrl !== undefined ? isDefaultServer$1(serverUrl) : true;
  var setInputValue = function (field, e) {
    var stringValue = e.target.value;
    var value = stringValue === "" ? undefined : stringValue;
    return Curry._1(dispatch, /* SetField */Block.__(3, [
                  field,
                  value
                ]));
  };
  var getHelperText = function (value) {
    if (hasSubmitted && value === undefined) {
      return "required";
    } else {
      return "";
    }
  };
  var addStringVal = function (name, value, arr) {
    if (value !== undefined) {
      return Belt_Array.concat(arr, [/* tuple */[
                    name,
                    /* `String */[
                      -976970511,
                      Caml_option.valFromOption(value)
                    ]
                  ]]);
    } else {
      return arr;
    }
  };
  var getFormBody = function (state) {
    var arg = [
      /* tuple */[
        "type",
        /* `Int */[
          3654863,
          4
        ]
      ],
      /* tuple */[
        "attachmentStrategy",
        /* `Int */[
          3654863,
          DataSourcesApi$BsConsole.AttachmentStrategy.fromVariant(state.attachmentStrategy)
        ]
      ],
      /* tuple */[
        "extendAttributes",
        /* `Bool */[
          737456202,
          extendAttributes
        ]
      ],
      /* tuple */[
        "ignoreSslCert",
        /* `Bool */[
          737456202,
          ignoreSslCert
        ]
      ]
    ];
    var func = function (param, param$1) {
      return addStringVal("name", param, param$1);
    };
    var arr = Curry._2(func, state.name, arg);
    var match = state.projectIdOrFilter;
    var arg$1;
    if (match !== undefined) {
      if (match.tag) {
        var func$1 = function (param, param$1) {
          return addStringVal("projectId", param, param$1);
        };
        arg$1 = Curry._2(func$1, match[0], arr);
      } else {
        var func$2 = function (param, param$1) {
          return addStringVal("filter", param, param$1);
        };
        arg$1 = Curry._2(func$2, match[0], arr);
      }
    } else {
      arg$1 = arr;
    }
    var func$3 = function (param, param$1) {
      return addStringVal("user", param, param$1);
    };
    var arg$2 = Curry._2(func$3, state.user, arg$1);
    var func$4 = function (param, param$1) {
      return addStringVal("secret", param, param$1);
    };
    var arg$3 = Curry._2(func$4, state.secret, arg$2);
    var func$5 = function (param, param$1) {
      return addStringVal("serverUrl", param, param$1);
    };
    var arg$4 = Curry._2(func$5, state.serverUrl, arg$3);
    var func$6 = function (param, param$1) {
      return addStringVal("coronerdAddress", param, param$1);
    };
    var arg$5 = Curry._2(func$6, state.coronerdAddress, arg$4);
    var arg$6 = DataSourcesApi$BsConsole.prepareFormBody(Curry._1((function (param) {
                    return (function (param$1) {
                        return (function (param$2) {
                            return addNotificationFields(param, param$1, param$2, arg$5);
                          });
                      });
                  })("sendConnectionDisabledNotification")(areNotificationsAvailable), sendNotifications));
    var arg$7 = (function (param) {
          return DataSourcesApi$BsConsole.addAttributeMappingsInForm(param, arg$6);
        })(state.attributeMapping);
    return Curry._1((function (param) {
                      var func = function (param$1, param$2, param$3) {
                        return DataSourcesApi$BsConsole.addNotificationRecipientsInForm(param, param$1, param$2, param$3);
                      };
                      return (function (param) {
                          var func$1 = Curry._1(func, param);
                          return (function (param) {
                              return Curry._2(func$1, param, arg$7);
                            });
                        });
                    })(notificationRecipients)(sendNotifications), areNotificationsAvailable);
  };
  var secretExists = connection !== undefined ? connection.secretExists : false;
  var match$1 = React.useState((function () {
          return false;
        }));
  var setLoading = match$1[1];
  return React.createElement(DataSourcesConnectionForm$FormContainer, {
              title: I18N$BsConsole.get(undefined, "PlayStation 5 Integration"),
              logoSrc: DataSourcesApi$BsConsole.playstation5LogoInfo[0],
              logoAlt: DataSourcesApi$BsConsole.playstation5LogoInfo[1],
              logoClassName: DataSourcesApi$BsConsole.playstation5LogoInfo[2],
              disabled: connection !== undefined ? false : (
                  serverUrl !== undefined ? coronerdAddress$1 === undefined : true
                ),
              loading: match$1[0],
              onSave: (function (param) {
                  Curry._1(setLoading, (function (param) {
                          return true;
                        }));
                  Curry._1(dispatch, /* Send */0);
                  var formBody = getFormBody(state);
                  var connectionTask;
                  if (connection !== undefined) {
                    var partial_arg = connection.id;
                    connectionTask = (function (param) {
                        return DataSourcesApi$BsConsole.put(partial_arg, serviceEndpoint, param);
                      });
                  } else {
                    connectionTask = (function (param) {
                        return DataSourcesApi$BsConsole.post(projectName, serviceEndpoint, param);
                      });
                  }
                  var handleOnFailure = function (err) {
                    Curry._1(setLoading, (function (param) {
                            return false;
                          }));
                    return Curry._1(onFailure, err);
                  };
                  var arg = Curry._1(connectionTask, formBody);
                  return Curry._2((function (param) {
                                  return (function (param$1, param$2) {
                                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                    });
                                })(token), undefined, (function (param) {
                                return responseCb(onSuccess, handleOnFailure, param);
                              }));
                }),
              onClose: onClose,
              children: null
            }, React.createElement(FormControl.default, {
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* [] */0
                      ]),
                  children: null
                }, React.createElement(BtSettings$BsConsole.FormInput.make, {
                      placeholder: I18N$BsConsole.get(undefined, "my-connection-name"),
                      title: I18N$BsConsole.get(undefined, "Connection name"),
                      value: Belt_Option.getWithDefault(state.name, ""),
                      label: I18N$BsConsole.show(undefined, "Name used to identify the connection."),
                      onChange: (function (param) {
                          return setInputValue(/* Name */0, param);
                        })
                    }), React.createElement(Typography.default, {
                      variant: "subtitle2",
                      children: I18N$BsConsole.show(undefined, "Server URL")
                    }), React.createElement(Typography.default, {
                      variant: "caption",
                      classes: {
                        root: Css.style(/* :: */[
                              Css.marginBottom(Css.rem(0.5)),
                              /* [] */0
                            ])
                      },
                      children: I18N$BsConsole.show(undefined, "Back office server endpoint.")
                    }), React.createElement(DataSourcesConnectionForm$TextFieldWithDefaults, {
                      defaultOptions: defaultCrashServers,
                      value: Belt_Option.getWithDefault(serverUrl, ""),
                      onChange: (function (value) {
                          return Curry._1(dispatch, /* SetField */Block.__(3, [
                                        /* ServerUrl */3,
                                        value
                                      ]));
                        }),
                      ariaLabel: "service url",
                      placeholder: "https://ps5crashes.your-studio.com/recap/rest",
                      otherLabel: I18N$BsConsole.get(undefined, "Other/Self Hosted")
                    }), React.createElement(BtSettings$BsConsole.FormSectionTitle.make, {
                      title: I18N$BsConsole.get(undefined, "Authentication")
                    }), React.createElement(BtSettings$BsConsole.FormSectionLabel.make, {
                      label: I18N$BsConsole.show(undefined, "Authentication credentials for PlayStation 5 back office server.")
                    }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                      placeholder: I18N$BsConsole.get(undefined, "Client ID"),
                      value: Belt_Option.getWithDefault(user, ""),
                      onChange: (function (param) {
                          return setInputValue(/* User */1, param);
                        }),
                      error: hasSubmitted && user === undefined,
                      helperText: getHelperText(user)
                    }), React.createElement(DataSourcesConnectionForm$ApiTokenFormInput, {
                      placeholder: I18N$BsConsole.get(undefined, "Client Secret"),
                      value: state.secret,
                      onChange: (function (param) {
                          return setInputValue(/* Secret */2, param);
                        }),
                      secretExists: secretExists
                    }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                      placeholder: I18N$BsConsole.get(undefined, "Submission URL"),
                      title: I18N$BsConsole.get(undefined, "Submission URL"),
                      value: Belt_Option.getWithDefault(coronerdAddress$1, ""),
                      label: I18N$BsConsole.show(undefined, "The URL used to submit errors to your Backtrace instance (e.g. https://submit.backtrace.io/{your-subdomain}/{submission-token}/json)."),
                      onChange: (function (param) {
                          return setInputValue(/* CoronerdAddress */4, param);
                        }),
                      error: hasSubmitted && coronerdAddress$1 === undefined,
                      helperText: getHelperText(coronerdAddress$1)
                    }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                      placeholder: I18N$BsConsole.get(undefined, usingDefaultServer ? solrPlaceholder$1 : projPlaceholder$1),
                      title: I18N$BsConsole.get(undefined, usingDefaultServer ? solrTitle$1 : projTitle$1),
                      value: Belt_Option.getWithDefault(toValue$1(projectIdOrFilter), ""),
                      label: I18N$BsConsole.show(undefined, usingDefaultServer ? solrLabel$1 : projLabel$1),
                      onChange: (function (param) {
                          return setInputValue(/* ProjectIdOrFilter */5, param);
                        }),
                      error: hasSubmitted && toValue$1(projectIdOrFilter) === undefined,
                      helperText: getHelperText(toValue$1(projectIdOrFilter))
                    }), React.createElement(DataSourcesConnectionForm$AttachmentStrategy, {
                      value: state.attachmentStrategy,
                      onChange: (function (value) {
                          return Curry._1(dispatch, /* SetAttachmentStrategy */Block.__(0, [value]));
                        })
                    }), React.createElement(BtSettings$BsConsole.FormSectionTitle.make, {
                      title: I18N$BsConsole.get(undefined, "Extend attributes")
                    }), React.createElement(FormControlLabel.default, {
                      label: I18N$BsConsole.get(undefined, "Analyze and extend attributes from ORBISDMP files."),
                      control: React.createElement(Checkbox.default, {
                            checked: extendAttributes,
                            color: "primary",
                            onChange: (function ($$event) {
                                var value = $$event.target.checked;
                                return Curry._1(dispatch, /* SetExtendAttributes */Block.__(4, [value]));
                              })
                          })
                    }), React.createElement(BtSettings$BsConsole.FormSectionTitle.make, {
                      title: I18N$BsConsole.get(undefined, "Enable self signed certificates for back office server")
                    }), React.createElement(FormControlLabel.default, {
                      label: I18N$BsConsole.get(undefined, "Ignore SSL certificate validation."),
                      control: React.createElement(Checkbox.default, {
                            checked: ignoreSslCert,
                            color: "primary",
                            onChange: (function ($$event) {
                                var value = $$event.target.checked;
                                return Curry._1(dispatch, /* SetIgnoreSslCert */Block.__(5, [value]));
                              })
                          })
                    }), React.createElement(BtSettings$BsConsole.FormSectionTitle.make, {
                      title: I18N$BsConsole.get(undefined, "Attribute name mapping")
                    }), React.createElement(BtSettings$BsConsole.FormSectionLabel.make, {
                      label: I18N$BsConsole.show(undefined, "Rename attributes from your PlayStation 5 errors before they enter Backtrace storage.")
                    }), React.createElement(DataSourcesConnectionForm$AttributeMappingInput, {
                      onAddMapping: (function (from, to_) {
                          return Curry._1(dispatch, /* AddMapping */Block.__(1, [/* tuple */[
                                          from,
                                          to_
                                        ]]));
                        }),
                      onRemoveMapping: (function (from, to_) {
                          return Curry._1(dispatch, /* RemoveMapping */Block.__(2, [/* tuple */[
                                          from,
                                          to_
                                        ]]));
                        }),
                      attributeMapping: state.attributeMapping
                    }), React.createElement(DataSourcesConnectionForm$ConnectionNotificationsInput, {
                      users: users,
                      token: token,
                      serviceEndpoint: serviceEndpoint,
                      sendNotifications: sendNotifications,
                      notificationRecipients: notificationRecipients,
                      areNotificationsAvailable: areNotificationsAvailable,
                      handleChange: (function (value) {
                          return Curry._1(dispatch, /* SetSendNotifications */Block.__(6, [value]));
                        }),
                      handleRemove: (function (recipient) {
                          return Curry._1(dispatch, /* RemoveNotificationRecipient */Block.__(8, [recipient]));
                        }),
                      handleAdd: (function (recipient) {
                          return Curry._1(dispatch, /* AddNotificationRecipient */Block.__(7, [recipient]));
                        }),
                      handleSetAreNotificationsAvailable: (function (value) {
                          return Curry._1(dispatch, /* SetAreNotificationsAvailable */Block.__(9, [value]));
                        })
                    })), IdentityVariant$BsConsole.current >= 2 ? null : React.createElement(Typography.default, {
                    variant: "body1",
                    classes: {
                      root: Css.style(/* :: */[
                            Css.marginBottom(Css.rem(0.5)),
                            /* [] */0
                          ])
                    },
                    children: null
                  }, React.createElement("span", undefined, I18N$BsConsole.show(undefined, "For more information please contact ")), React.createElement("a", {
                        href: "mailto:" + Email$BsConsole.support,
                        rel: "noreferrer noopener"
                      }, I18N$BsConsole.show(undefined, "customer support")), React.createElement("span", undefined, I18N$BsConsole.show(undefined, "."))));
}

var Playstation5Form = {
  serverUrl: serverUrl,
  defaultCrashServers: defaultCrashServers,
  isDefaultServer: isDefaultServer$1,
  ProjectIdOrFilter: ProjectIdOrFilter$1,
  getInitialState: getInitialState$1,
  reducer: reducer$2,
  make: DataSourcesConnectionForm$Playstation5Form
};

var defaultNintendoServer1 = "https://crash-report.mng.nintendo.net";

var defaultNintendoServer2 = "https://dd1-crash-report.mng.nintendo.net";

function getInitialState$2(connection, coronerdAddress) {
  if (connection === undefined) {
    return {
            name: undefined,
            projectId: undefined,
            secret: undefined,
            serverUrl: defaultNintendoServer1,
            coronerdAddress: coronerdAddress,
            attributeMapping: [],
            hasSubmitted: false,
            attachmentStrategy: /* Full */0,
            sendNotifications: true,
            notificationRecipients: [],
            areNotificationsAvailable: false
          };
  }
  var connectionNotifications = connection.connectionNotifications;
  var connectionNotifications$1 = connection.connectionNotifications;
  return {
          name: connection.name,
          projectId: connection.projectId,
          secret: undefined,
          serverUrl: connection.serverUrl,
          coronerdAddress: connection.coronerdAddress,
          attributeMapping: connection.attributeMapping,
          hasSubmitted: false,
          attachmentStrategy: connection.attachmentStrategy,
          sendNotifications: connectionNotifications !== undefined ? connectionNotifications.sendNotifications : true,
          notificationRecipients: connectionNotifications$1 !== undefined ? connectionNotifications$1.notificationRecipients : [],
          areNotificationsAvailable: false
        };
}

function reducer$3(state, action) {
  if (typeof action === "number") {
    return {
            name: state.name,
            projectId: state.projectId,
            secret: state.secret,
            serverUrl: state.serverUrl,
            coronerdAddress: state.coronerdAddress,
            attributeMapping: state.attributeMapping,
            hasSubmitted: true,
            attachmentStrategy: state.attachmentStrategy,
            sendNotifications: state.sendNotifications,
            notificationRecipients: state.notificationRecipients,
            areNotificationsAvailable: state.areNotificationsAvailable
          };
  }
  switch (action.tag | 0) {
    case /* AddMapping */0 :
        var newAttributeMapping = Belt_Array.concat(state.attributeMapping, [action[0]]);
        return {
                name: state.name,
                projectId: state.projectId,
                secret: state.secret,
                serverUrl: state.serverUrl,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: newAttributeMapping,
                hasSubmitted: state.hasSubmitted,
                attachmentStrategy: state.attachmentStrategy,
                sendNotifications: state.sendNotifications,
                notificationRecipients: state.notificationRecipients,
                areNotificationsAvailable: state.areNotificationsAvailable
              };
    case /* RemoveMapping */1 :
        var match = action[0];
        var mapV = match[1];
        var mapK = match[0];
        var newAttributeMapping$1 = Belt_Array.keep(state.attributeMapping, (function (param) {
                if (mapK !== param[0]) {
                  return mapV !== param[1];
                } else {
                  return false;
                }
              }));
        return {
                name: state.name,
                projectId: state.projectId,
                secret: state.secret,
                serverUrl: state.serverUrl,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: newAttributeMapping$1,
                hasSubmitted: state.hasSubmitted,
                attachmentStrategy: state.attachmentStrategy,
                sendNotifications: state.sendNotifications,
                notificationRecipients: state.notificationRecipients,
                areNotificationsAvailable: state.areNotificationsAvailable
              };
    case /* SetField */2 :
        var value = action[1];
        switch (action[0]) {
          case /* Name */0 :
              return {
                      name: value,
                      projectId: state.projectId,
                      secret: state.secret,
                      serverUrl: state.serverUrl,
                      coronerdAddress: state.coronerdAddress,
                      attributeMapping: state.attributeMapping,
                      hasSubmitted: state.hasSubmitted,
                      attachmentStrategy: state.attachmentStrategy,
                      sendNotifications: state.sendNotifications,
                      notificationRecipients: state.notificationRecipients,
                      areNotificationsAvailable: state.areNotificationsAvailable
                    };
          case /* ProjectId */1 :
              return {
                      name: state.name,
                      projectId: value,
                      secret: state.secret,
                      serverUrl: state.serverUrl,
                      coronerdAddress: state.coronerdAddress,
                      attributeMapping: state.attributeMapping,
                      hasSubmitted: state.hasSubmitted,
                      attachmentStrategy: state.attachmentStrategy,
                      sendNotifications: state.sendNotifications,
                      notificationRecipients: state.notificationRecipients,
                      areNotificationsAvailable: state.areNotificationsAvailable
                    };
          case /* Secret */2 :
              return {
                      name: state.name,
                      projectId: state.projectId,
                      secret: value,
                      serverUrl: state.serverUrl,
                      coronerdAddress: state.coronerdAddress,
                      attributeMapping: state.attributeMapping,
                      hasSubmitted: state.hasSubmitted,
                      attachmentStrategy: state.attachmentStrategy,
                      sendNotifications: state.sendNotifications,
                      notificationRecipients: state.notificationRecipients,
                      areNotificationsAvailable: state.areNotificationsAvailable
                    };
          case /* ServerUrl */3 :
              return {
                      name: state.name,
                      projectId: state.projectId,
                      secret: state.secret,
                      serverUrl: value,
                      coronerdAddress: state.coronerdAddress,
                      attributeMapping: state.attributeMapping,
                      hasSubmitted: state.hasSubmitted,
                      attachmentStrategy: state.attachmentStrategy,
                      sendNotifications: state.sendNotifications,
                      notificationRecipients: state.notificationRecipients,
                      areNotificationsAvailable: state.areNotificationsAvailable
                    };
          case /* CoronerdAddress */4 :
              return {
                      name: state.name,
                      projectId: state.projectId,
                      secret: state.secret,
                      serverUrl: state.serverUrl,
                      coronerdAddress: value,
                      attributeMapping: state.attributeMapping,
                      hasSubmitted: state.hasSubmitted,
                      attachmentStrategy: state.attachmentStrategy,
                      sendNotifications: state.sendNotifications,
                      notificationRecipients: state.notificationRecipients,
                      areNotificationsAvailable: state.areNotificationsAvailable
                    };
          
        }
    case /* SetAttachmentStrategy */3 :
        return {
                name: state.name,
                projectId: state.projectId,
                secret: state.secret,
                serverUrl: state.serverUrl,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: state.attributeMapping,
                hasSubmitted: state.hasSubmitted,
                attachmentStrategy: action[0],
                sendNotifications: state.sendNotifications,
                notificationRecipients: state.notificationRecipients,
                areNotificationsAvailable: state.areNotificationsAvailable
              };
    case /* SetSendNotifications */4 :
        return {
                name: state.name,
                projectId: state.projectId,
                secret: state.secret,
                serverUrl: state.serverUrl,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: state.attributeMapping,
                hasSubmitted: state.hasSubmitted,
                attachmentStrategy: state.attachmentStrategy,
                sendNotifications: action[0],
                notificationRecipients: state.notificationRecipients,
                areNotificationsAvailable: state.areNotificationsAvailable
              };
    case /* AddNotificationRecipient */5 :
        var newNotificationRecipients = Belt_Array.concat(state.notificationRecipients, [action[0]]);
        return {
                name: state.name,
                projectId: state.projectId,
                secret: state.secret,
                serverUrl: state.serverUrl,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: state.attributeMapping,
                hasSubmitted: state.hasSubmitted,
                attachmentStrategy: state.attachmentStrategy,
                sendNotifications: state.sendNotifications,
                notificationRecipients: newNotificationRecipients,
                areNotificationsAvailable: state.areNotificationsAvailable
              };
    case /* RemoveNotificationRecipient */6 :
        var recipient = action[0];
        var newNotificationRecipients$1 = Belt_Array.keep(state.notificationRecipients, (function (r) {
                return r !== recipient;
              }));
        return {
                name: state.name,
                projectId: state.projectId,
                secret: state.secret,
                serverUrl: state.serverUrl,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: state.attributeMapping,
                hasSubmitted: state.hasSubmitted,
                attachmentStrategy: state.attachmentStrategy,
                sendNotifications: state.sendNotifications,
                notificationRecipients: newNotificationRecipients$1,
                areNotificationsAvailable: state.areNotificationsAvailable
              };
    case /* SetAreNotificationsAvailable */7 :
        return {
                name: state.name,
                projectId: state.projectId,
                secret: state.secret,
                serverUrl: state.serverUrl,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: state.attributeMapping,
                hasSubmitted: state.hasSubmitted,
                attachmentStrategy: state.attachmentStrategy,
                sendNotifications: state.sendNotifications,
                notificationRecipients: state.notificationRecipients,
                areNotificationsAvailable: action[0]
              };
    
  }
}

function DataSourcesConnectionForm$NintendoSwitchForm(Props) {
  var users = Props.users;
  var token = Props.token;
  var projectName = Props.projectName;
  var serviceEndpoint = Props.serviceEndpoint;
  var onSuccess = Props.onSuccess;
  var onFailure = Props.onFailure;
  var connection = Props.connection;
  var coronerdAddress = Props.coronerdAddress;
  var onClose = Props.onClose;
  var match = React.useReducer(reducer$3, getInitialState$2(connection, coronerdAddress));
  var state = match[0];
  var areNotificationsAvailable = state.areNotificationsAvailable;
  var notificationRecipients = state.notificationRecipients;
  var sendNotifications = state.sendNotifications;
  var hasSubmitted = state.hasSubmitted;
  var coronerdAddress$1 = state.coronerdAddress;
  var serverUrl = state.serverUrl;
  var projectId = state.projectId;
  var dispatch = match[1];
  var setInputValue = function (field, e) {
    var stringValue = e.target.value;
    var value = stringValue === "" ? undefined : stringValue;
    return Curry._1(dispatch, /* SetField */Block.__(2, [
                  field,
                  value
                ]));
  };
  var getHelperText = function (value) {
    if (hasSubmitted && value === undefined) {
      return "required";
    } else {
      return "";
    }
  };
  var addStringVal = function (name, value, arr) {
    if (value !== undefined) {
      return Belt_Array.concat(arr, [/* tuple */[
                    name,
                    /* `String */[
                      -976970511,
                      Caml_option.valFromOption(value)
                    ]
                  ]]);
    } else {
      return arr;
    }
  };
  var getFormBody = function (state) {
    var arg = [
      /* tuple */[
        "type",
        /* `Int */[
          3654863,
          2
        ]
      ],
      /* tuple */[
        "attachmentStrategy",
        /* `Int */[
          3654863,
          DataSourcesApi$BsConsole.AttachmentStrategy.fromVariant(state.attachmentStrategy)
        ]
      ]
    ];
    var func = function (param, param$1) {
      return addStringVal("name", param, param$1);
    };
    var arg$1 = Curry._2(func, state.name, arg);
    var func$1 = function (param, param$1) {
      return addStringVal("projectId", param, param$1);
    };
    var arg$2 = Curry._2(func$1, state.projectId, arg$1);
    var func$2 = function (param, param$1) {
      return addStringVal("secret", param, param$1);
    };
    var arg$3 = Curry._2(func$2, state.secret, arg$2);
    var func$3 = function (param, param$1) {
      return addStringVal("serverUrl", param, param$1);
    };
    var arg$4 = Curry._2(func$3, state.serverUrl, arg$3);
    var func$4 = function (param, param$1) {
      return addStringVal("coronerdAddress", param, param$1);
    };
    var arg$5 = Curry._2(func$4, state.coronerdAddress, arg$4);
    var arg$6 = DataSourcesApi$BsConsole.prepareFormBody(Curry._1((function (param) {
                    return (function (param$1) {
                        return (function (param$2) {
                            return addNotificationFields(param, param$1, param$2, arg$5);
                          });
                      });
                  })("sendConnectionDisabledNotification")(areNotificationsAvailable), sendNotifications));
    var arg$7 = (function (param) {
          return DataSourcesApi$BsConsole.addAttributeMappingsInForm(param, arg$6);
        })(state.attributeMapping);
    return Curry._1((function (param) {
                      var func = function (param$1, param$2, param$3) {
                        return DataSourcesApi$BsConsole.addNotificationRecipientsInForm(param, param$1, param$2, param$3);
                      };
                      return (function (param) {
                          var func$1 = Curry._1(func, param);
                          return (function (param) {
                              return Curry._2(func$1, param, arg$7);
                            });
                        });
                    })(notificationRecipients)(sendNotifications), areNotificationsAvailable);
  };
  var secretExists = connection !== undefined ? connection.secretExists : false;
  var match$1 = React.useState((function () {
          return false;
        }));
  var setLoading = match$1[1];
  return React.createElement(DataSourcesConnectionForm$FormContainer, {
              title: I18N$BsConsole.get(undefined, "Nintendo Switch Integration"),
              logoSrc: DataSourcesApi$BsConsole.nintendoSwitchLogoInfo[0],
              logoAlt: DataSourcesApi$BsConsole.nintendoSwitchLogoInfo[1],
              logoClassName: DataSourcesApi$BsConsole.nintendoSwitchLogoInfo[2],
              disabled: connection !== undefined ? false : (
                  serverUrl !== undefined && coronerdAddress$1 !== undefined ? projectId === undefined : true
                ),
              loading: match$1[0],
              onSave: (function (param) {
                  Curry._1(setLoading, (function (param) {
                          return true;
                        }));
                  Curry._1(dispatch, /* Send */0);
                  var formBody = getFormBody(state);
                  var connectionTask;
                  if (connection !== undefined) {
                    var partial_arg = connection.id;
                    connectionTask = (function (param) {
                        return DataSourcesApi$BsConsole.put(partial_arg, serviceEndpoint, param);
                      });
                  } else {
                    connectionTask = (function (param) {
                        return DataSourcesApi$BsConsole.post(projectName, serviceEndpoint, param);
                      });
                  }
                  var handleOnFailure = function (err) {
                    Curry._1(setLoading, (function (param) {
                            return false;
                          }));
                    return Curry._1(onFailure, err);
                  };
                  var arg = Curry._1(connectionTask, formBody);
                  return Curry._2((function (param) {
                                  return (function (param$1, param$2) {
                                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                    });
                                })(token), undefined, (function (param) {
                                return responseCb(onSuccess, handleOnFailure, param);
                              }));
                }),
              onClose: onClose,
              children: null
            }, React.createElement(FormControl.default, {
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* [] */0
                      ]),
                  children: null
                }, React.createElement(BtSettings$BsConsole.FormInput.make, {
                      placeholder: I18N$BsConsole.get(undefined, "my-connection-name"),
                      title: I18N$BsConsole.get(undefined, "Connection name"),
                      value: Belt_Option.getWithDefault(state.name, ""),
                      label: I18N$BsConsole.show(undefined, "Name used to identify the connection."),
                      onChange: (function (param) {
                          return setInputValue(/* Name */0, param);
                        })
                    }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                      placeholder: I18N$BsConsole.get(undefined, "Application ID"),
                      title: I18N$BsConsole.get(undefined, "Application ID"),
                      value: Belt_Option.getWithDefault(projectId, ""),
                      label: I18N$BsConsole.show(undefined, "Nintendo Switch application ID"),
                      onChange: (function (param) {
                          return setInputValue(/* ProjectId */1, param);
                        })
                    }), React.createElement(Typography.default, {
                      variant: "subtitle2",
                      children: I18N$BsConsole.show(undefined, "Server URL")
                    }), React.createElement(Typography.default, {
                      variant: "caption",
                      classes: {
                        root: Css.style(/* :: */[
                              Css.marginBottom(Css.rem(0.5)),
                              /* [] */0
                            ])
                      },
                      children: I18N$BsConsole.show(undefined, "Nintendo Switch server URL")
                    }), React.createElement(DataSourcesConnectionForm$TextFieldWithDefaults, {
                      defaultOptions: [
                        /* tuple */[
                          defaultNintendoServer1,
                          defaultNintendoServer1
                        ],
                        /* tuple */[
                          defaultNintendoServer2,
                          defaultNintendoServer2
                        ]
                      ],
                      value: Belt_Option.getWithDefault(serverUrl, ""),
                      onChange: (function (value) {
                          return Curry._1(dispatch, /* SetField */Block.__(2, [
                                        /* ServerUrl */3,
                                        value
                                      ]));
                        }),
                      ariaLabel: "service url"
                    }), React.createElement(BtSettings$BsConsole.FormSectionTitle.make, {
                      title: I18N$BsConsole.get(undefined, "API token")
                    }), React.createElement(BtSettings$BsConsole.FormSectionLabel.make, {
                      label: I18N$BsConsole.show(undefined, "API token for your Nintendo Switch server")
                    }), React.createElement(DataSourcesConnectionForm$ApiTokenFormInput, {
                      value: state.secret,
                      onChange: (function (param) {
                          return setInputValue(/* Secret */2, param);
                        }),
                      secretExists: secretExists
                    }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                      placeholder: I18N$BsConsole.get(undefined, "Submission URL"),
                      title: I18N$BsConsole.get(undefined, "Submission URL"),
                      value: Belt_Option.getWithDefault(coronerdAddress$1, ""),
                      label: I18N$BsConsole.show(undefined, "The URL used to submit errors to your Backtrace instance (e.g. https://submit.backtrace.io/{your-subdomain}/{submission-token}/json)."),
                      onChange: (function (param) {
                          return setInputValue(/* CoronerdAddress */4, param);
                        }),
                      error: hasSubmitted && coronerdAddress$1 === undefined,
                      helperText: getHelperText(coronerdAddress$1)
                    }), React.createElement(BtSettings$BsConsole.FormSectionTitle.make, {
                      title: I18N$BsConsole.get(undefined, "Attribute name mapping")
                    }), React.createElement(BtSettings$BsConsole.FormSectionLabel.make, {
                      label: I18N$BsConsole.show(undefined, "Rename attributes from your Nintendo Switch errors before they enter Backtrace storage.")
                    }), React.createElement(DataSourcesConnectionForm$AttributeMappingInput, {
                      onAddMapping: (function (from, to_) {
                          return Curry._1(dispatch, /* AddMapping */Block.__(0, [/* tuple */[
                                          from,
                                          to_
                                        ]]));
                        }),
                      onRemoveMapping: (function (from, to_) {
                          return Curry._1(dispatch, /* RemoveMapping */Block.__(1, [/* tuple */[
                                          from,
                                          to_
                                        ]]));
                        }),
                      attributeMapping: state.attributeMapping
                    })), React.createElement(DataSourcesConnectionForm$AttachmentStrategy, {
                  value: state.attachmentStrategy,
                  onChange: (function (value) {
                      return Curry._1(dispatch, /* SetAttachmentStrategy */Block.__(3, [value]));
                    })
                }), React.createElement(DataSourcesConnectionForm$ConnectionNotificationsInput, {
                  users: users,
                  token: token,
                  serviceEndpoint: serviceEndpoint,
                  sendNotifications: sendNotifications,
                  notificationRecipients: notificationRecipients,
                  areNotificationsAvailable: areNotificationsAvailable,
                  handleChange: (function (value) {
                      return Curry._1(dispatch, /* SetSendNotifications */Block.__(4, [value]));
                    }),
                  handleRemove: (function (recipient) {
                      return Curry._1(dispatch, /* RemoveNotificationRecipient */Block.__(6, [recipient]));
                    }),
                  handleAdd: (function (recipient) {
                      return Curry._1(dispatch, /* AddNotificationRecipient */Block.__(5, [recipient]));
                    }),
                  handleSetAreNotificationsAvailable: (function (value) {
                      return Curry._1(dispatch, /* SetAreNotificationsAvailable */Block.__(7, [value]));
                    })
                }), IdentityVariant$BsConsole.current >= 2 ? null : React.createElement(Typography.default, {
                    variant: "body1",
                    classes: {
                      root: Css.style(/* :: */[
                            Css.marginBottom(Css.rem(0.5)),
                            /* [] */0
                          ])
                    },
                    children: null
                  }, React.createElement("span", undefined, I18N$BsConsole.show(undefined, "For more information please contact ")), React.createElement("a", {
                        href: "mailto:" + Email$BsConsole.support,
                        rel: "noreferrer noopener"
                      }, I18N$BsConsole.show(undefined, "customer support")), React.createElement("span", undefined, I18N$BsConsole.show(undefined, "."))));
}

var NintendoSwitchForm = {
  defaultNintendoServer1: defaultNintendoServer1,
  defaultNintendoServer2: defaultNintendoServer2,
  getInitialState: getInitialState$2,
  reducer: reducer$3,
  make: DataSourcesConnectionForm$NintendoSwitchForm
};

function getInitialState$3(connection, coronerdAddress) {
  if (connection === undefined) {
    return {
            name: undefined,
            projectId: undefined,
            user: undefined,
            secret: undefined,
            bucketName: undefined,
            coronerdAddress: coronerdAddress,
            attributeMapping: [],
            hasSubmitted: false,
            sendNotifications: true,
            notificationRecipients: [],
            areNotificationsAvailable: false
          };
  }
  var connectionNotifications = connection.connectionNotifications;
  var connectionNotifications$1 = connection.connectionNotifications;
  return {
          name: connection.name,
          projectId: connection.projectId,
          user: connection.user,
          secret: undefined,
          bucketName: connection.bucketName,
          coronerdAddress: connection.coronerdAddress,
          attributeMapping: connection.attributeMapping,
          hasSubmitted: false,
          sendNotifications: connectionNotifications !== undefined ? connectionNotifications.sendNotifications : true,
          notificationRecipients: connectionNotifications$1 !== undefined ? connectionNotifications$1.notificationRecipients : [],
          areNotificationsAvailable: false
        };
}

function reducer$4(state, action) {
  if (typeof action === "number") {
    return {
            name: state.name,
            projectId: state.projectId,
            user: state.user,
            secret: state.secret,
            bucketName: state.bucketName,
            coronerdAddress: state.coronerdAddress,
            attributeMapping: state.attributeMapping,
            hasSubmitted: true,
            sendNotifications: state.sendNotifications,
            notificationRecipients: state.notificationRecipients,
            areNotificationsAvailable: state.areNotificationsAvailable
          };
  }
  switch (action.tag | 0) {
    case /* AddMapping */0 :
        var newAttributeMapping = Belt_Array.concat(state.attributeMapping, [action[0]]);
        return {
                name: state.name,
                projectId: state.projectId,
                user: state.user,
                secret: state.secret,
                bucketName: state.bucketName,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: newAttributeMapping,
                hasSubmitted: state.hasSubmitted,
                sendNotifications: state.sendNotifications,
                notificationRecipients: state.notificationRecipients,
                areNotificationsAvailable: state.areNotificationsAvailable
              };
    case /* RemoveMapping */1 :
        var match = action[0];
        var mapV = match[1];
        var mapK = match[0];
        var newAttributeMapping$1 = Belt_Array.keep(state.attributeMapping, (function (param) {
                if (mapK !== param[0]) {
                  return mapV !== param[1];
                } else {
                  return false;
                }
              }));
        return {
                name: state.name,
                projectId: state.projectId,
                user: state.user,
                secret: state.secret,
                bucketName: state.bucketName,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: newAttributeMapping$1,
                hasSubmitted: state.hasSubmitted,
                sendNotifications: state.sendNotifications,
                notificationRecipients: state.notificationRecipients,
                areNotificationsAvailable: state.areNotificationsAvailable
              };
    case /* SetField */2 :
        var value = action[1];
        switch (action[0]) {
          case /* Name */0 :
              return {
                      name: value,
                      projectId: state.projectId,
                      user: state.user,
                      secret: state.secret,
                      bucketName: state.bucketName,
                      coronerdAddress: state.coronerdAddress,
                      attributeMapping: state.attributeMapping,
                      hasSubmitted: state.hasSubmitted,
                      sendNotifications: state.sendNotifications,
                      notificationRecipients: state.notificationRecipients,
                      areNotificationsAvailable: state.areNotificationsAvailable
                    };
          case /* ProjectId */1 :
              return {
                      name: state.name,
                      projectId: value,
                      user: state.user,
                      secret: state.secret,
                      bucketName: state.bucketName,
                      coronerdAddress: state.coronerdAddress,
                      attributeMapping: state.attributeMapping,
                      hasSubmitted: state.hasSubmitted,
                      sendNotifications: state.sendNotifications,
                      notificationRecipients: state.notificationRecipients,
                      areNotificationsAvailable: state.areNotificationsAvailable
                    };
          case /* User */2 :
              return {
                      name: state.name,
                      projectId: state.projectId,
                      user: value,
                      secret: state.secret,
                      bucketName: state.bucketName,
                      coronerdAddress: state.coronerdAddress,
                      attributeMapping: state.attributeMapping,
                      hasSubmitted: state.hasSubmitted,
                      sendNotifications: state.sendNotifications,
                      notificationRecipients: state.notificationRecipients,
                      areNotificationsAvailable: state.areNotificationsAvailable
                    };
          case /* Secret */3 :
              return {
                      name: state.name,
                      projectId: state.projectId,
                      user: state.user,
                      secret: value,
                      bucketName: state.bucketName,
                      coronerdAddress: state.coronerdAddress,
                      attributeMapping: state.attributeMapping,
                      hasSubmitted: state.hasSubmitted,
                      sendNotifications: state.sendNotifications,
                      notificationRecipients: state.notificationRecipients,
                      areNotificationsAvailable: state.areNotificationsAvailable
                    };
          case /* BucketName */4 :
              return {
                      name: state.name,
                      projectId: state.projectId,
                      user: state.user,
                      secret: state.secret,
                      bucketName: value,
                      coronerdAddress: state.coronerdAddress,
                      attributeMapping: state.attributeMapping,
                      hasSubmitted: state.hasSubmitted,
                      sendNotifications: state.sendNotifications,
                      notificationRecipients: state.notificationRecipients,
                      areNotificationsAvailable: state.areNotificationsAvailable
                    };
          case /* CoronerdAddress */5 :
              return {
                      name: state.name,
                      projectId: state.projectId,
                      user: state.user,
                      secret: state.secret,
                      bucketName: state.bucketName,
                      coronerdAddress: value,
                      attributeMapping: state.attributeMapping,
                      hasSubmitted: state.hasSubmitted,
                      sendNotifications: state.sendNotifications,
                      notificationRecipients: state.notificationRecipients,
                      areNotificationsAvailable: state.areNotificationsAvailable
                    };
          
        }
    case /* SetSendNotifications */3 :
        return {
                name: state.name,
                projectId: state.projectId,
                user: state.user,
                secret: state.secret,
                bucketName: state.bucketName,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: state.attributeMapping,
                hasSubmitted: state.hasSubmitted,
                sendNotifications: action[0],
                notificationRecipients: state.notificationRecipients,
                areNotificationsAvailable: state.areNotificationsAvailable
              };
    case /* AddNotificationRecipient */4 :
        var newNotificationRecipients = Belt_Array.concat(state.notificationRecipients, [action[0]]);
        return {
                name: state.name,
                projectId: state.projectId,
                user: state.user,
                secret: state.secret,
                bucketName: state.bucketName,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: state.attributeMapping,
                hasSubmitted: state.hasSubmitted,
                sendNotifications: state.sendNotifications,
                notificationRecipients: newNotificationRecipients,
                areNotificationsAvailable: state.areNotificationsAvailable
              };
    case /* RemoveNotificationRecipient */5 :
        var recipient = action[0];
        var newNotificationRecipients$1 = Belt_Array.keep(state.notificationRecipients, (function (r) {
                return r !== recipient;
              }));
        return {
                name: state.name,
                projectId: state.projectId,
                user: state.user,
                secret: state.secret,
                bucketName: state.bucketName,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: state.attributeMapping,
                hasSubmitted: state.hasSubmitted,
                sendNotifications: state.sendNotifications,
                notificationRecipients: newNotificationRecipients$1,
                areNotificationsAvailable: state.areNotificationsAvailable
              };
    case /* SetAreNotificationsAvailable */6 :
        return {
                name: state.name,
                projectId: state.projectId,
                user: state.user,
                secret: state.secret,
                bucketName: state.bucketName,
                coronerdAddress: state.coronerdAddress,
                attributeMapping: state.attributeMapping,
                hasSubmitted: state.hasSubmitted,
                sendNotifications: state.sendNotifications,
                notificationRecipients: state.notificationRecipients,
                areNotificationsAvailable: action[0]
              };
    
  }
}

function DataSourcesConnectionForm$XBoxForm(Props) {
  var users = Props.users;
  var token = Props.token;
  var projectName = Props.projectName;
  var serviceEndpoint = Props.serviceEndpoint;
  var onSuccess = Props.onSuccess;
  var onFailure = Props.onFailure;
  var connection = Props.connection;
  var coronerdAddress = Props.coronerdAddress;
  var onClose = Props.onClose;
  var match = React.useReducer(reducer$4, getInitialState$3(connection, coronerdAddress));
  var state = match[0];
  var areNotificationsAvailable = state.areNotificationsAvailable;
  var notificationRecipients = state.notificationRecipients;
  var sendNotifications = state.sendNotifications;
  var hasSubmitted = state.hasSubmitted;
  var coronerdAddress$1 = state.coronerdAddress;
  var bucketName = state.bucketName;
  var user = state.user;
  var projectId = state.projectId;
  var dispatch = match[1];
  var setInputValue = function (field, e) {
    var stringValue = e.target.value;
    var value = stringValue === "" ? undefined : stringValue;
    return Curry._1(dispatch, /* SetField */Block.__(2, [
                  field,
                  value
                ]));
  };
  var getHelperText = function (value) {
    if (hasSubmitted && value === undefined) {
      return "required";
    } else {
      return "";
    }
  };
  var addStringVal = function (name, value, arr) {
    if (value !== undefined) {
      return Belt_Array.concat(arr, [/* tuple */[
                    name,
                    /* `String */[
                      -976970511,
                      Caml_option.valFromOption(value)
                    ]
                  ]]);
    } else {
      return arr;
    }
  };
  var getFormBody = function (state) {
    var arg = [/* tuple */[
        "type",
        /* `Int */[
          3654863,
          3
        ]
      ]];
    var func = function (param, param$1) {
      return addStringVal("name", param, param$1);
    };
    var arg$1 = Curry._2(func, state.name, arg);
    var func$1 = function (param, param$1) {
      return addStringVal("projectId", param, param$1);
    };
    var arg$2 = Curry._2(func$1, state.projectId, arg$1);
    var func$2 = function (param, param$1) {
      return addStringVal("user", param, param$1);
    };
    var arg$3 = Curry._2(func$2, state.user, arg$2);
    var func$3 = function (param, param$1) {
      return addStringVal("secret", param, param$1);
    };
    var arg$4 = Curry._2(func$3, state.secret, arg$3);
    var func$4 = function (param, param$1) {
      return addStringVal("bucketName", param, param$1);
    };
    var arg$5 = Curry._2(func$4, state.bucketName, arg$4);
    var func$5 = function (param, param$1) {
      return addStringVal("coronerdAddress", param, param$1);
    };
    var arg$6 = Curry._2(func$5, state.coronerdAddress, arg$5);
    var arg$7 = DataSourcesApi$BsConsole.prepareFormBody(Curry._1((function (param) {
                    return (function (param$1) {
                        return (function (param$2) {
                            return addNotificationFields(param, param$1, param$2, arg$6);
                          });
                      });
                  })("sendConnectionDisabledNotification")(areNotificationsAvailable), sendNotifications));
    var arg$8 = (function (param) {
          return DataSourcesApi$BsConsole.addAttributeMappingsInForm(param, arg$7);
        })(state.attributeMapping);
    return Curry._1((function (param) {
                      var func = function (param$1, param$2, param$3) {
                        return DataSourcesApi$BsConsole.addNotificationRecipientsInForm(param, param$1, param$2, param$3);
                      };
                      return (function (param) {
                          var func$1 = Curry._1(func, param);
                          return (function (param) {
                              return Curry._2(func$1, param, arg$8);
                            });
                        });
                    })(notificationRecipients)(sendNotifications), areNotificationsAvailable);
  };
  var secretExists = connection !== undefined ? connection.secretExists : false;
  var match$1 = React.useState((function () {
          return false;
        }));
  var setLoading = match$1[1];
  return React.createElement(DataSourcesConnectionForm$FormContainer, {
              title: I18N$BsConsole.get(undefined, "Microsoft Xbox Integration"),
              logoSrc: DataSourcesApi$BsConsole.xBoxLogoInfo[0],
              logoAlt: DataSourcesApi$BsConsole.xBoxLogoInfo[1],
              logoClassName: DataSourcesApi$BsConsole.xBoxLogoInfo[2],
              disabled: connection !== undefined ? false : (
                  bucketName !== undefined && coronerdAddress$1 !== undefined ? projectId === undefined : true
                ),
              loading: match$1[0],
              onSave: (function (param) {
                  Curry._1(setLoading, (function (param) {
                          return true;
                        }));
                  Curry._1(dispatch, /* Send */0);
                  var formBody = getFormBody(state);
                  var connectionTask;
                  if (connection !== undefined) {
                    var partial_arg = connection.id;
                    connectionTask = (function (param) {
                        return DataSourcesApi$BsConsole.put(partial_arg, serviceEndpoint, param);
                      });
                  } else {
                    connectionTask = (function (param) {
                        return DataSourcesApi$BsConsole.post(projectName, serviceEndpoint, param);
                      });
                  }
                  var handleOnFailure = function (err) {
                    Curry._1(setLoading, (function (param) {
                            return false;
                          }));
                    return Curry._1(onFailure, err);
                  };
                  var arg = Curry._1(connectionTask, formBody);
                  return Curry._2((function (param) {
                                  return (function (param$1, param$2) {
                                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                    });
                                })(token), undefined, (function (param) {
                                return responseCb(onSuccess, handleOnFailure, param);
                              }));
                }),
              onClose: onClose,
              children: null
            }, React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(0.5)),
                        /* :: */[
                          Css.padding2(Css.px(4), Css.rem(0.5)),
                          /* :: */[
                            Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.orangeDark)),
                            /* :: */[
                              Css.backgroundColor(Css.hex(Colors$BsConsole.orangeBackground)),
                              /* :: */[
                                Css.borderRadius(Css.px(4)),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]),
                  children: null
                }, React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.color(Css.hex(Colors$BsConsole.orangeDark)),
                            /* :: */[
                              Css.marginRight(Css.rem(0.5)),
                              /* [] */0
                            ]
                          ])
                    }, React.createElement(Warning.default, {
                          color: "inherit"
                        })), IdentityVariant$BsConsole.current >= 2 ? React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body2 */9,
                        children: I18N$BsConsole.show(undefined, "We recommend using our Xbox SDK.")
                      }) : React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body2 */9,
                        children: null
                      }, I18N$BsConsole.show(undefined, "We recommend using our Xbox SDK. Please contact "), React.createElement("a", {
                            href: "mailto:" + Email$BsConsole.support,
                            rel: "noreferrer noopener"
                          }, I18N$BsConsole.show(undefined, "customer support")), I18N$BsConsole.show(undefined, " for more information."))), React.createElement(FormControl.default, {
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* [] */0
                      ]),
                  children: null
                }, React.createElement(BtSettings$BsConsole.FormInput.make, {
                      placeholder: I18N$BsConsole.get(undefined, "my-connection-name"),
                      title: I18N$BsConsole.get(undefined, "Connection name"),
                      value: Belt_Option.getWithDefault(state.name, ""),
                      label: I18N$BsConsole.show(undefined, "Name used to identify the connection."),
                      onChange: (function (param) {
                          return setInputValue(/* Name */0, param);
                        })
                    }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                      placeholder: I18N$BsConsole.get(undefined, "Game ID"),
                      title: I18N$BsConsole.get(undefined, "Game ID"),
                      value: Belt_Option.getWithDefault(projectId, ""),
                      label: I18N$BsConsole.show(undefined, "Microsoft Xbox game ID."),
                      onChange: (function (param) {
                          return setInputValue(/* ProjectId */1, param);
                        })
                    }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                      placeholder: I18N$BsConsole.get(undefined, "Tenant ID"),
                      title: I18N$BsConsole.get(undefined, "Tenant ID"),
                      value: Belt_Option.getWithDefault(bucketName, ""),
                      label: I18N$BsConsole.show(undefined, "Microsoft tenant ID."),
                      onChange: (function (param) {
                          return setInputValue(/* BucketName */4, param);
                        })
                    }), React.createElement(BtSettings$BsConsole.FormSectionTitle.make, {
                      title: I18N$BsConsole.get(undefined, "Authentication")
                    }), React.createElement(BtSettings$BsConsole.FormSectionLabel.make, {
                      label: I18N$BsConsole.show(undefined, "Microsoft Azure AD client credentials.")
                    }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                      placeholder: I18N$BsConsole.get(undefined, "Username"),
                      value: Belt_Option.getWithDefault(user, ""),
                      onChange: (function (param) {
                          return setInputValue(/* User */2, param);
                        }),
                      error: hasSubmitted && user === undefined,
                      helperText: getHelperText(user)
                    }), React.createElement(DataSourcesConnectionForm$ApiTokenFormInput, {
                      placeholder: I18N$BsConsole.get(undefined, "Password"),
                      value: state.secret,
                      onChange: (function (param) {
                          return setInputValue(/* Secret */3, param);
                        }),
                      secretExists: secretExists
                    }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                      placeholder: I18N$BsConsole.get(undefined, "Submission URL"),
                      title: I18N$BsConsole.get(undefined, "Submission URL"),
                      value: Belt_Option.getWithDefault(coronerdAddress$1, ""),
                      label: I18N$BsConsole.show(undefined, "The URL used to submit errors to your Backtrace instance (e.g. https://submit.backtrace.io/{your-subdomain}/{submission-token}/json)."),
                      onChange: (function (param) {
                          return setInputValue(/* CoronerdAddress */5, param);
                        }),
                      error: hasSubmitted && coronerdAddress$1 === undefined,
                      helperText: getHelperText(coronerdAddress$1)
                    }), React.createElement(BtSettings$BsConsole.FormSectionTitle.make, {
                      title: I18N$BsConsole.get(undefined, "Attribute name mapping")
                    }), React.createElement(BtSettings$BsConsole.FormSectionLabel.make, {
                      label: I18N$BsConsole.show(undefined, "Rename attributes from your Microsoft Xbox errors before they enter Backtrace storage.")
                    }), React.createElement(DataSourcesConnectionForm$AttributeMappingInput, {
                      onAddMapping: (function (from, to_) {
                          return Curry._1(dispatch, /* AddMapping */Block.__(0, [/* tuple */[
                                          from,
                                          to_
                                        ]]));
                        }),
                      onRemoveMapping: (function (from, to_) {
                          return Curry._1(dispatch, /* RemoveMapping */Block.__(1, [/* tuple */[
                                          from,
                                          to_
                                        ]]));
                        }),
                      attributeMapping: state.attributeMapping
                    }), React.createElement(DataSourcesConnectionForm$ConnectionNotificationsInput, {
                      users: users,
                      token: token,
                      serviceEndpoint: serviceEndpoint,
                      sendNotifications: sendNotifications,
                      notificationRecipients: notificationRecipients,
                      areNotificationsAvailable: areNotificationsAvailable,
                      handleChange: (function (value) {
                          return Curry._1(dispatch, /* SetSendNotifications */Block.__(3, [value]));
                        }),
                      handleRemove: (function (recipient) {
                          return Curry._1(dispatch, /* RemoveNotificationRecipient */Block.__(5, [recipient]));
                        }),
                      handleAdd: (function (recipient) {
                          return Curry._1(dispatch, /* AddNotificationRecipient */Block.__(4, [recipient]));
                        }),
                      handleSetAreNotificationsAvailable: (function (value) {
                          return Curry._1(dispatch, /* SetAreNotificationsAvailable */Block.__(6, [value]));
                        })
                    })), IdentityVariant$BsConsole.current >= 2 ? null : React.createElement(Typography.default, {
                    variant: "body1",
                    classes: {
                      root: Css.style(/* :: */[
                            Css.marginBottom(Css.rem(0.5)),
                            /* [] */0
                          ])
                    },
                    children: null
                  }, React.createElement("span", undefined, I18N$BsConsole.show(undefined, "For more information please contact ")), React.createElement("a", {
                        href: "mailto:" + Email$BsConsole.support,
                        rel: "noreferrer noopener"
                      }, I18N$BsConsole.show(undefined, "customer support")), React.createElement("span", undefined, I18N$BsConsole.show(undefined, "."))));
}

var XBoxForm = {
  getInitialState: getInitialState$3,
  reducer: reducer$4,
  make: DataSourcesConnectionForm$XBoxForm
};

function DataSourcesConnectionForm(Props) {
  var config = Props.config;
  var form = Props.form;
  var refetch = Props.refetch;
  var onClose = Props.onClose;
  var tokensOpt = Props.tokens;
  var tokens = tokensOpt !== undefined ? tokensOpt : [];
  var token = Belt_Option.getExn(React.useContext(TokenContext$BsConsole.ctx));
  var projectName = React.useContext(ProjectNameContext$BsConsole.ctx);
  var serviceEndpoint = Belt_Option.getExn(Services$BsConsole.getEndpoint(undefined)(config.services, "connector"));
  var submissionToken = Belt_Array.get(tokens, 0);
  var submissionEndpoint = submissionToken !== undefined ? ({
        json: Shell$BsConsole.getSubmissionEndpoint(submissionToken, config, /* JSON */0),
        minidump: Shell$BsConsole.getSubmissionEndpoint(submissionToken, config, /* MiniDump */1)
      }) : ({
        json: undefined,
        minidump: undefined
      });
  var onCreateSuccess = function (eventName, param) {
    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [eventName]));
    Curry._1(refetch, undefined);
    Curry._1(onClose, undefined);
    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully created data source "));
  };
  var onModifySuccess = function (eventName, param) {
    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [eventName]));
    Curry._1(refetch, undefined);
    Curry._1(onClose, undefined);
    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully modified data source "));
  };
  var onFailure = function (msg) {
    Curry._1(refetch, undefined);
    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                        /* String_literal */Block.__(11, [
                            "Error: ",
                            /* String */Block.__(2, [
                                /* No_padding */0,
                                /* End_of_format */0
                              ])
                          ]),
                        "Error: %s"
                      ]), msg));
  };
  var users = Belt_List.sort(Belt_Option.getWithDefault(config.users, /* [] */0), (function (u1, u2) {
          return Caml_primitive.caml_string_compare(u1.email.toLowerCase(), u2.email.toLowerCase());
        }));
  if (form.tag) {
    var connection = form[0];
    switch (connection.tag | 0) {
      case /* Playstation4 */0 :
          return React.createElement(DataSourcesConnectionForm$Playstation4Form, {
                      users: users,
                      token: token,
                      projectName: projectName,
                      serviceEndpoint: serviceEndpoint,
                      onSuccess: (function (param) {
                          return onModifySuccess(/* DataSource_Playstation4_Modify */70, param);
                        }),
                      onFailure: onFailure,
                      connection: connection[0],
                      coronerdAddress: submissionEndpoint.json,
                      onClose: onClose
                    });
      case /* NintendoSwitch */1 :
          return React.createElement(DataSourcesConnectionForm$NintendoSwitchForm, {
                      users: users,
                      token: token,
                      projectName: projectName,
                      serviceEndpoint: serviceEndpoint,
                      onSuccess: (function (param) {
                          return onModifySuccess(/* DataSource_NintendoSwitch_Modify */74, param);
                        }),
                      onFailure: onFailure,
                      connection: connection[0],
                      coronerdAddress: submissionEndpoint.json,
                      onClose: onClose
                    });
      case /* XBox */2 :
          return React.createElement(DataSourcesConnectionForm$XBoxForm, {
                      users: users,
                      token: token,
                      projectName: projectName,
                      serviceEndpoint: serviceEndpoint,
                      onSuccess: (function (param) {
                          return onCreateSuccess(/* DataSource_XBox_Modify */76, param);
                        }),
                      onFailure: onFailure,
                      connection: connection[0],
                      coronerdAddress: submissionEndpoint.json,
                      onClose: onClose
                    });
      case /* Playstation5 */3 :
          return React.createElement(DataSourcesConnectionForm$Playstation5Form, {
                      users: users,
                      token: token,
                      projectName: projectName,
                      serviceEndpoint: serviceEndpoint,
                      onSuccess: (function (param) {
                          return onModifySuccess(/* DataSource_Playstation5_Modify */72, param);
                        }),
                      onFailure: onFailure,
                      connection: connection[0],
                      coronerdAddress: submissionEndpoint.json,
                      onClose: onClose
                    });
      case /* Unity */4 :
          return null;
      
    }
  } else {
    switch (form[0]) {
      case /* Playstation4 */0 :
          return React.createElement(DataSourcesConnectionForm$Playstation4Form, {
                      users: users,
                      token: token,
                      projectName: projectName,
                      serviceEndpoint: serviceEndpoint,
                      onSuccess: (function (param) {
                          return onCreateSuccess(/* DataSource_Playstation4_Create */69, param);
                        }),
                      onFailure: onFailure,
                      coronerdAddress: submissionEndpoint.json,
                      onClose: onClose
                    });
      case /* NintendoSwitch */1 :
          return React.createElement(DataSourcesConnectionForm$NintendoSwitchForm, {
                      users: users,
                      token: token,
                      projectName: projectName,
                      serviceEndpoint: serviceEndpoint,
                      onSuccess: (function (param) {
                          return onCreateSuccess(/* DataSource_NintendoSwitch_Create */73, param);
                        }),
                      onFailure: onFailure,
                      coronerdAddress: submissionEndpoint.json,
                      onClose: onClose
                    });
      case /* XBox */2 :
          return React.createElement(DataSourcesConnectionForm$XBoxForm, {
                      users: users,
                      token: token,
                      projectName: projectName,
                      serviceEndpoint: serviceEndpoint,
                      onSuccess: (function (param) {
                          return onCreateSuccess(/* DataSource_XBox_Create */75, param);
                        }),
                      onFailure: onFailure,
                      coronerdAddress: submissionEndpoint.json,
                      onClose: onClose
                    });
      case /* Playstation5 */3 :
          return React.createElement(DataSourcesConnectionForm$Playstation5Form, {
                      users: users,
                      token: token,
                      projectName: projectName,
                      serviceEndpoint: serviceEndpoint,
                      onSuccess: (function (param) {
                          return onCreateSuccess(/* DataSource_Playstation5_Create */71, param);
                        }),
                      onFailure: onFailure,
                      coronerdAddress: submissionEndpoint.json,
                      onClose: onClose
                    });
      
    }
  }
}

var make = DataSourcesConnectionForm;

exports.responseCb = responseCb;
exports.trimValueOrNone = trimValueOrNone;
exports.FormContainer = FormContainer;
exports.AttributeMappingInput = AttributeMappingInput;
exports.NotificationRecipientsInput = NotificationRecipientsInput;
exports.ConnectionNotificationsInput = ConnectionNotificationsInput;
exports.radioRoot = radioRoot;
exports.TextFieldWithDefaults = TextFieldWithDefaults;
exports.AttachmentStrategy = AttachmentStrategy;
exports.ApiTokenFormInput = ApiTokenFormInput;
exports.Playstation4Form = Playstation4Form;
exports.Playstation5Form = Playstation5Form;
exports.NintendoSwitchForm = NintendoSwitchForm;
exports.XBoxForm = XBoxForm;
exports.make = make;
/* container Not a pure module */
